import * as React from 'react';
import { QuestionNode } from '../quiz.interfaces';
import { QuestionTypeRadio } from './question-type-radio';
import { QuestionTypePresentation } from './question-type-presentation';
import { QuestionTypeAddress } from './question-type-address';
import { QuestionTypeBoxSelection } from './question-type-box-selection';
import { QuestionTypeEmailContact } from './question-type-email';
import { QuestionTypeJobSelection } from './question-type-searchable';
import { QuestionTypeTagSelection } from './question-type-tag-selection';

export const componentByKind: {
  [K in QuestionNode['kind']]: React.FunctionComponent<any> /* (props: any) => ReactNode; */;
} = {
  radio: QuestionTypeRadio,
  presentation: QuestionTypePresentation,
  address: QuestionTypeAddress,
  box_selection: QuestionTypeBoxSelection,
  email_contact: QuestionTypeEmailContact,
  job_selection: QuestionTypeJobSelection,
  tag_selection: QuestionTypeTagSelection,
};
