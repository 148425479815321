import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { List, ListItemButton } from '@mui/material';
import { styledBoxes } from '@/react/components/quiz/utils/styled-boxes';
import { styled } from '@mui/material/styles';
import { pluralize } from '@/react/components/quiz/utils/pluralize';

const Styled = styledBoxes('TagSelection', {
  Stack: {
    component: Stack,
    width: '100%',
  },

  Helper: {
    component: styled('span')`
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;
      font-size: 16px;
    `,
  },

  List: {
    component: styled(List)`
      width: 100%;
      display: grid;
      gap: 20px;
    `,
    sx: { gridTemplateColumns: { xs: '1fr 1fr' } },
  },
  ItemWrapper: {
    component: styled(ListItemButton)`
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      align-content: center;
      border-radius: 10px;
      box-shadow: #f2f2f2 inset 0 0 0 1px;
      &.checked {
        box-shadow: #7e0b80 inset 0 0 0 1px;
      }
      height: 84px;
      padding: 5px 0;
      font-size: 16px;
    `,
  },
  Img: {
    component: 'img',
    sx: {
      maxWidth: '30px',
    },
  },
});

type Option = {
  value: string;
  icon: string;
  label: string;
};

type QuestionTypeBoxSelectionProps = {
  validation: any;
  value: string[];
  touched: boolean;
  setErrors: (errors: string[]) => void;
  errors: string[];
  setValue: (value: string[]) => void;
  options: Option[];
};

export function QuestionTypeBoxSelection({
  validation,
  value,
  touched,
  setErrors,
  errors,
  setValue,
  options,
}: QuestionTypeBoxSelectionProps) {
  const { maxSelectedCount, minSelectedCount } = validation;
  const { length } = value;

  useEffect(() => {
    if (!touched) return;

    const minNOK = length < minSelectedCount;
    const maxNOK = length > maxSelectedCount;

    if (minNOK) {
      setErrors([
        `Selecione ao menos ${minSelectedCount} ${pluralize(
          minSelectedCount,
          'item',
          'itens'
        )}.`,
      ]);
    } else if (maxNOK) {
      return setErrors([
        `Selecione no máximo ${maxSelectedCount} ${pluralize(
          maxSelectedCount,
          'item',
          'itens'
        )}.`,
      ]);
    } else if (errors.length) {
      setErrors([]);
    }
  }, [maxSelectedCount, minSelectedCount, length, touched, errors.length]);

  return (
    <Styled.Stack>
      <Styled.Helper>
        Selecione até {maxSelectedCount} opções.
        <span>
          {length}/{maxSelectedCount}
        </span>
      </Styled.Helper>

      <Styled.List>
        {options.map((option, idx) => {
          const checked = value.includes(option.value);

          const onClick = () => {
            const checked = value.includes(option.value);
            const next = checked
              ? value.filter((el) => el !== option.value)
              : [...value, option.value];

            setValue(next);
          };

          return (
            <Styled.ItemWrapper
              key={option.value + idx}
              onClick={onClick}
              className={{ checked, 'not-checked': !checked }}
            >
              <Styled.Img src={option.icon} alt={'icon'} />

              <span>{option.label}</span>
            </Styled.ItemWrapper>
          );
        })}
      </Styled.List>
    </Styled.Stack>
  );
}
