import * as React from 'react';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';

import { WizardStepComponent } from '@/react/components/wizard/interfaces';

import { useWizard } from '@/react/components/wizard/state';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';

export const GenerateRecommendation: React.ElementType<WizardStepComponent> = ({
  onPrevious,
}) => {
  const [query, setQuery] = React.useState('idle');
  const timerRef = React.useRef<ReturnType<typeof setTimeout>>(undefined);
  const { actions: wizardActions } = useWizard();

  const handleNext = async () => {
    wizardActions.navigate(CreatePlanWizardSteps.RECOMMENDATION_REVIEW);
  };

  const handleQuery = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (query !== 'idle') {
      setQuery('idle');
      return;
    }

    setQuery('progress');
    timerRef.current = setTimeout(() => {
      setQuery('success');
      handleNext();
    }, 10000);
  };

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  React.useEffect(() => {
    handleQuery();
  }, []);

  return (
    <>
      <Box
        mt={3}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box sx={{ height: 40 }}>
          {query !== 'success' && (
            <Fade
              in={query === 'progress'}
              style={{
                transitionDelay: query === 'progress' ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <CircularProgress data-testid="loading" />
            </Fade>
          )}
        </Box>

        <Box mt={3}>
          <Typography variant="h6">Gerando recomendações...</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="body3">
            Estamos processando suas respostas para criar um plano alinhado ao
            seu objetivo e às habilidades que você deseja desenvolver.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
