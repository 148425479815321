import * as React from 'react';
import Box from '@mui/material/Box';

import { WizardStep } from './interfaces';

const StepWrapper = ({ children }) => {
  return <Box>{children}</Box>;
};

const Step: React.ElementType<WizardStep> = ({
  component: Component,
  onNext,
  onPrevious,
  titleElement,
}) => {
  return (
    <StepWrapper>
      {titleElement}
      <Component onNext={onNext} onPrevious={onPrevious} />
    </StepWrapper>
  );
};

export default Step;
