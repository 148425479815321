import { useMutation } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';

interface useCreateRecommendationParams {
  onSuccess: (data) => void;
  onError: () => void;
}
export function useCreateRecommendation({
  onSuccess,
  onError,
}: useCreateRecommendationParams) {
  const { toast } = useNotification();
  const { mutate: createRecommendation, isPending } = useMutation({
    mutationFn: async (stepId: CreatePlanWizardSteps) => {
      return await IdpClient.createRecommendationPlan(stepId);
    },
    onError: (error: any) => {
      Sentry.captureException(error);

      toast({
        content: 'Erro ao criar recomendação.',
        type: 'error',
      });

      onError();
    },
    onSuccess: onSuccess,
  });

  return {
    createRecommendation,
    isPending,
  };
}
