import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

const Styled = {
  SelectedChip: styled(Chip)(({ theme }) => ({
    color: theme.palette.primary[700],
    borderColor: theme.palette.primary[700],
    backgroundColor: theme.palette.primary[100],
    '&&:hover': {
      backgroundColor: theme.palette.primary[100],
    },
    '& .MuiChip-deleteIcon *': {
      color: theme.palette.primary[700],
    },
  })),
  SelectionHelper: styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    top: -20px;
    height: 20px;
    margin-bottom: 10px;
    background: white;
    display: flex;
    width: 100%;
  `,
  Content: styled(Stack)`
    overflow: hidden;
    overflow-y: auto;
  `,
};

interface TagSelectProps {
  options: { label: string; value: string; checked: boolean }[];
  setOptions: (
    options: { label: string; value: string; checked: boolean }[]
  ) => void;
  maxSelected?: number;
}

export const TagSelect = ({
  options,
  setOptions,
  maxSelected = 5,
}: TagSelectProps) => {
  const [internalOptions, setInternalOptions] = useState(options);

  const onClick = (index: number) => {
    setInternalOptions((prevOptions) => {
      const selectedCount = prevOptions.filter((opt) => opt.checked).length;
      const isCurrentlyChecked = prevOptions[index].checked;

      if (!isCurrentlyChecked && selectedCount >= maxSelected) {
        return prevOptions;
      }

      const newOptions = [...prevOptions];
      newOptions[index].checked = !newOptions[index].checked;
      setOptions(newOptions);
      return newOptions;
    });
  };

  return (
    <>
      <Styled.Content
        direction="row"
        flexWrap={'wrap'}
        spacing={2}
        useFlexGap
        width={'100%'}
        data-testid="tag-select"
      >
        {internalOptions.map((option, index) => {
          const StyledChip = option.checked ? Styled.SelectedChip : Chip;

          return (
            <StyledChip
              key={option.value}
              label={option.label}
              onClick={() => onClick(index)}
              variant={'outlined'}
              onDelete={option.checked ? () => onClick(index) : null}
              deleteIcon={
                option.checked ? <CancelIcon color="primary" /> : null
              }
            />
          );
        })}
      </Styled.Content>
    </>
  );
};
