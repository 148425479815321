import React, { useEffect, useState } from 'react';
import { Alert, Box, Stack, Typography } from '@mui/material';

import { TagSelect } from '@/react/components/idp/shared';
import { useRecommendedSkills } from '@/react/data/idp/useRecommendedSkills';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWizard } from '@/react/components/wizard/state';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { z } from 'zod';

interface skill {
  label: string;
  value: string;
  checked: boolean;
}

export const validationSchema = z.object({
  skillsList: z
    .object({
      label: z.string(),
      value: z.string(),
      checked: z.boolean(),
    })
    .array()
    .min(1, 'Selecione pelo menos uma habilidade para continuar.')
    .max(18),
});

const mapRecommendationsWithCheckedStatus = (
  recommendations: skill[],
  selectedSkills: skill[]
) => {
  const selectedSkillsMap = new Map(
    selectedSkills.map((item) => [item.value, item.checked])
  );
  return recommendations.map((targetItem) => ({
    ...targetItem,
    checked: selectedSkillsMap.get(targetItem.value) ?? targetItem.checked,
  }));
};

export const PlanSkillSelectionForm: React.ElementType<WizardStepComponent> = ({
  onNext,
  onPrevious,
}) => {
  const { actions: wizardActions, values } = useWizard();
  const defaultValues = {
    skillsList: values[CreatePlanWizardSteps.SKILL_SELECTION] || [],
  };
  const { formState, handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  const { recommendations, isFetching } = useRecommendedSkills();
  const [skillsList, setSkillsList] = useState([]);

  useEffect(() => {
    const watchedValues = watch();

    const formattedRecommendations =
      watchedValues.skillsList.length > 0
        ? mapRecommendationsWithCheckedStatus(
            recommendations,
            watchedValues.skillsList
          )
        : recommendations;

    setSkillsList(formattedRecommendations);
  }, [isFetching]);

  const setSelectedSkills = (skills) => {
    setSkillsList(skills);
    const selectedSkills = skills.filter((skill) => skill.checked);

    setValue('skillsList', selectedSkills);
  };

  const handleNext = async (data) => {
    trigger('skillsList');

    wizardActions.updateValues({
      [CreatePlanWizardSteps.SKILL_SELECTION]: data.skillsList,
    });

    if (onNext) {
      onNext();
    }
  };

  return (
    <>
      <Box mt={3}>
        <Typography variant="h6">
          Quais habilidades você deseja desenvolver com esse plano?
        </Typography>
        <Typography variant="body3">
          Selecione até 5 habilidades para que dê tempo de desenvolvê-las de
          forma adequada.
        </Typography>
      </Box>

      <Box mt={3}>
        <form onSubmit={handleSubmit(handleNext)}>
          <Stack spacing={2}>
            {skillsList.length > 0 && (
              <TagSelect options={skillsList} setOptions={setSelectedSkills} />
            )}
            {formState.isSubmitted && formState.errors['skillsList'] && (
              <Alert severity="error">
                {formState.errors['skillsList'].message as string}
              </Alert>
            )}
          </Stack>
          <WizardStepActions>
            <LoadingButton color="primary" onClick={onPrevious} variant="text">
              Voltar
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                trigger();
              }}
              color="primary"
              type="submit"
              variant="contained"
            >
              Próximo
            </LoadingButton>
          </WizardStepActions>
        </form>
      </Box>
    </>
  );
};
