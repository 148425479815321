import React from 'react';
import { Box, Typography } from '@mui/material';
import { IdpDialogBody } from './styles';
import { ConfirmDialog } from '@/react/components/dialog/confirm-dialog.component';

export interface IdpConfirmDialogProps {
  actionLabels: {
    cancel: string;
    confirm: string;
  };
  description: string;
  image?: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  subtitle: string;
  title: string;
}

export const IdpConfirmDialog = ({
  actionLabels,
  description,
  image,
  onClose,
  onConfirm,
  open,
  subtitle,
  title,
}: IdpConfirmDialogProps) => {
  return (
    <ConfirmDialog
      actionLabels={actionLabels}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
    >
      <IdpDialogBody>
        <Box
          component="img"
          src={image}
          sx={{ height: 128, width: 128, margin: '0 auto 8px' }}
        />
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {subtitle}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {description}
        </Typography>
      </IdpDialogBody>
    </ConfirmDialog>
  );
};
