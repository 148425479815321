import React from 'react';
import NukaCarousel from 'nuka-carousel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styledBoxes } from '../utils/styled-boxes';
import { NL2BR } from '../utils/NL2BR';

const renderNull = () => <></>;

const Styled = styledBoxes('Presentation', {
  Wrapper: {
    component: Stack,
    sx: {
      height: '100%',
      justifyContent: 'space-between',
      '.slider-control-bottomcenter': {
        marginBottom: '-10px',
      },
      '.slider-container': {
        marginBottom: 1,
      },
    },
  },
  Title: {
    width: '100%',
    component: Typography,
    sx: { marginBottom: (theme) => theme.spacing(2) },
  },
  Subtitle: {
    width: '100%',
    component: Typography,
  },
  P: {
    component: 'p',
    marginBottom: '2px',
  },
  CarrouselItem: {
    component: Stack,
    gap: 1,
    textAlign: 'center',
    minHeight: 360,
  },
  Img: {
    component: 'img',
    sx: {
      objectFit: 'contain',
      objectPosition: 'center',
      width: '100%',
      maxHeight: 200,
    },
  },
});

export function QuestionTypePresentation({
  value,
  setValue,
  submit,
  exitQuiz,
  goBack,
  forceCloseQuiz,
  showFooterActions,
  props,
}) {
  const slideIndex = value[0] ? +value[0] : 0;
  const { slides, buttons } = props;

  return (
    <Styled.Wrapper>
      <NukaCarousel
        slideIndex={slideIndex}
        afterSlide={(index) => setValue([`${index}`])}
        renderCenterLeftControls={renderNull}
        renderCenterRightControls={renderNull}
        renderBottomCenterControls={slides.length > 1 ? undefined : renderNull}
      >
        {slides.map((slide, idx) => {
          const { title, subtitle } = slide;

          return (
            <Styled.CarrouselItem key={idx + slide.img}>
              <Styled.Img src={slide.img} alt={title} title={title} />

              <Styled.Title variant={'h6'}>
                <NL2BR>{title}</NL2BR>
              </Styled.Title>

              <Styled.Subtitle variant={'body2'}>
                <NL2BR>{subtitle}</NL2BR>
              </Styled.Subtitle>
            </Styled.CarrouselItem>
          );
        })}
      </NukaCarousel>

      {!showFooterActions && (
        <Stack className={'buttons'} gap={1}>
          {buttons.map((el, idx) => (
            <Button
              color={'primary'}
              variant={el.kind}
              key={idx + el.label}
              onClick={() => {
                const actions = {
                  continue_quiz: submit,
                  exit_quiz: exitQuiz,
                  force_close: forceCloseQuiz,
                  go_back: goBack,
                };
                actions[el.action]();
              }}
            >
              {el.label}
            </Button>
          ))}
        </Stack>
      )}
    </Styled.Wrapper>
  );
}
