import { RecommendationTypeSelect } from '@/react/components/idp/recommendation-type-select';
import { Component } from '@angular/core';

@Component({
  selector: 'app-recommendation-type',
  templateUrl: './recommendation-type.component.html',
})
export class IdpRecommendationTypeComponent {
  IdpRecommendationTypeComponent = RecommendationTypeSelect;
}
