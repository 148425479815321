import { Component } from '@angular/core';

import RefundManagement from '@/react/components/refund/refund-management/refund-management.component';

@Component({
  selector: 'component-refund-management',
  templateUrl: './refund-management.component.html',
})
export class RefundManagementComponent {
  RefundManagement = RefundManagement;
}
