import * as assets from './assets';
import { labelsToOptions } from '@/react/utils/labelsToOptions';
import type { QuestionNode } from '@/react/components/quiz/quiz.interfaces';
import type { BootstrapInterfaces } from '@/react/data/bootstrap';
import { Profile } from '@/react/data/recommendation/interfaces';
import { filterNull, IS_BROWSER } from 'powership';
import { ResultUseBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { enumerate } from '@/react/components/quiz/utils/enumerate';
import {
  GOALS,
  LANGUAGES,
  LanguagesLabels,
  SCHOLARITIES,
  ScholaritiesLabels,
  SENIORITIES,
  SenioritiesLabels,
} from '@/react/data/recommendation/constants';
import { formatMoney } from '@/react/utils/money';

export const QUESTIONS_ENUM = enumerate([
  'presentation_welcome',
  'presentation_welcome_step_2',
  'presentation_update_preferences',
  'location_info',
  'current_job',
  'scholarity',
  'seniority',
  'presentation_objective_selection',
  'goals',
  'desired_job',
  'presentation_learn_your_way',
  'select_course_interests',
  'select_most_interesting_themes',
  'select_interesting_languages',
  'select_best_learning_modality',
  'select_monthly_budget',
  'time_available_per_week',
  'input_contact_email',
  'presentation_finished',
  'presentation_ask_before_close',
]);

export const QuestionNames = QUESTIONS_ENUM.value;
export type QuestionId = typeof QUESTIONS_ENUM.infer;

preloadAssets();

type EditingProfileData = {
  [key in QuestionId]: (props?: {
    profile?: Partial<Profile>;
    taxonomy?: any;
  }) => QuestionNode;
};

export const editingProfileSettings: EditingProfileData = {
  presentation_welcome: () => ({
    id: QuestionNames.presentation_welcome,
    title: 'Boas vindas!',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [] },
    kind: 'presentation',
    props: {
      slides: [
        {
          title: 'Boas vindas!',
          img: assets.setupUser,
          subtitle:
            'A Galena é uma plataforma completa para seu desenvolvimento.\n' +
            '\n' +
            'Aqui você vai encontrar os recursos que te impulsionarão para o próximo passo da sua carreira!',
        },
      ],
      buttons: [
        {
          kind: 'contained',
          label: 'Vamos lá',
          action: 'continue_quiz',
        },
      ],
    },
    showFooterActions: false,
    required: false,
  }),

  presentation_welcome_step_2: () => ({
    id: QuestionNames.presentation_welcome_step_2,
    title: 'Personalize sua experiência',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [] },
    kind: 'presentation',
    props: {
      slides: [
        {
          title: 'Personalize sua experiência',
          img: assets.setupUserSlide2,
          subtitle:
            'Para recomendarmos os conteúdos certos para você alcançar seus objetivos, precisamos que responda algumas perguntas.\n' +
            '\n' +
            'Demora apenas 1 minuto!',
        },
      ],
      buttons: [],
    },
    showFooterActions: true,
    required: false,
  }),

  presentation_update_preferences: () => ({
    id: QuestionNames.presentation_update_preferences,
    title: 'Atualize as suas preferências',

    touched: false,
    value: [],
    errors: [],
    draftState: { value: [] },
    kind: 'presentation',
    props: {
      slides: [
        {
          title: 'Atualize as suas preferências',
          img: assets.updateUser,
          subtitle:
            'Revise seus Objetivos e Interesses para receber recomendações personalizadas para o seu desenvolvimento.',
        },
      ],

      buttons: [
        {
          kind: 'contained',
          label: 'Vamos lá',
          action: 'continue_quiz',
        },
      ],
    },
    required: false,
    showFooterActions: false,
  }),

  location_info: ({ profile }) => ({
    id: QuestionNames.location_info,
    title: 'Onde você mora?',
    touched: false,
    value: [],
    errors: [],
    draftState: {
      value: [
        profile.address_state, //
        profile.address_city,
      ],
    },
    kind: 'address',
    props: {},
    required: true,
  }),

  scholarity: ({ profile }) => ({
    id: QuestionNames.scholarity,
    title: 'Qual é a sua formação atual?',
    touched: false,
    value: [],
    errors: [],
    draftState: {
      value: [profile.scholarity],
    },
    kind: 'radio',

    props: {
      options: [
        {
          label: ScholaritiesLabels[SCHOLARITIES.HIGH_SCHOOL_INCOMPLETE],
          value: SCHOLARITIES.HIGH_SCHOOL_INCOMPLETE,
        },
        {
          label: ScholaritiesLabels[SCHOLARITIES.HIGH_SCHOOL_COMPLETE],
          value: SCHOLARITIES.HIGH_SCHOOL_COMPLETE,
        },
        {
          label: ScholaritiesLabels[SCHOLARITIES.UNDERGRADUATE_INCOMPLETE],
          value: SCHOLARITIES.UNDERGRADUATE_INCOMPLETE,
        },
        {
          label: ScholaritiesLabels[SCHOLARITIES.UNDERGRADUATE_COMPLETE],
          value: SCHOLARITIES.UNDERGRADUATE_COMPLETE,
        },
        {
          label: ScholaritiesLabels[SCHOLARITIES.POSTGRADUATE],
          value: SCHOLARITIES.POSTGRADUATE,
        },
        {
          label: ScholaritiesLabels[SCHOLARITIES.DOCTORAL],
          value: SCHOLARITIES.DOCTORAL,
        },
      ],
    },
    required: true,
  }),

  current_job: ({ profile }) => ({
    id: QuestionNames.current_job,
    title: 'Qual é o seu cargo atual?',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [profile.current_job?.id?.toString()] },
    meta: {
      jobReloadedFromProfile: profile.current_job,
    },
    kind: 'job_selection',
    props: {
      searchPlaceholder: 'Procure o seu cargo atual',
      searchHelperText:
        'Selecione a opção que mais se adequa ao seu cargo atual.',
      allowMarkAsOptionNotFound: false,
      optionNotFoundText: 'Não encontrei o meu cargo.',
    },
    required: true,
  }),

  seniority: ({ profile }) => ({
    id: QuestionNames.seniority,
    title: 'Qual nível de senioridade você ocupa hoje?',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [profile?.seniority] },
    kind: 'radio',
    props: {
      options: [
        {
          label: SenioritiesLabels[SENIORITIES.APPRENTICE],
          value: SENIORITIES.APPRENTICE,
        },
        {
          label: SenioritiesLabels[SENIORITIES.INTERN],
          value: SENIORITIES.INTERN,
        },
        {
          label: SenioritiesLabels[SENIORITIES.ATTENDANT],
          value: SENIORITIES.ATTENDANT,
        },
        {
          label: SenioritiesLabels[SENIORITIES.OPERATOR],
          value: SENIORITIES.OPERATOR,
        },
        {
          label: SenioritiesLabels[SENIORITIES.TECHNICIAN],
          value: SENIORITIES.TECHNICIAN,
        },
        {
          label: SenioritiesLabels[SENIORITIES.AUXILIARY],
          value: SENIORITIES.AUXILIARY,
        },
        {
          label: SenioritiesLabels[SENIORITIES.ASSISTANT],
          value: SENIORITIES.ASSISTANT,
        },
        {
          label: SenioritiesLabels[SENIORITIES.ANAYLIST],
          value: SENIORITIES.ANAYLIST,
        },
        {
          label: SenioritiesLabels[SENIORITIES.SUPERVISOR],
          value: SENIORITIES.SUPERVISOR,
        },
        {
          label: SenioritiesLabels[SENIORITIES.COORDINATOR],
          value: SENIORITIES.COORDINATOR,
        },
        {
          label: SenioritiesLabels[SENIORITIES.SPECIALIST],
          value: SENIORITIES.SPECIALIST,
        },
        {
          label: SenioritiesLabels[SENIORITIES.MANAGER],
          value: SENIORITIES.MANAGER,
        },
        {
          label: SenioritiesLabels[SENIORITIES.DIRECTOR],
          value: SENIORITIES.DIRECTOR,
        },
        {
          label: SenioritiesLabels[SENIORITIES.VICE_PRESIDENT],
          value: SENIORITIES.VICE_PRESIDENT,
        },
      ],
    },
    required: true,
  }),

  presentation_objective_selection: () => ({
    title: 'Defina seu objetivo',
    id: QuestionNames.presentation_objective_selection,
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [] },
    kind: 'presentation',
    props: {
      slides: [
        {
          title: 'Defina seu objetivo',
          img: assets.objectiveMountain,
          subtitle:
            'Aonde você quer chegar? Defina seu objetivo e aspiração ' +
            'profissional para receber recomendações mais precisas.',
        },
      ],
      buttons: [
        {
          kind: 'contained',
          label: 'Continuar',
          action: 'continue_quiz',
        },
      ],
    },
    required: false,
  }),

  goals: ({ profile }) => ({
    id: QuestionNames.goals,
    title:
      'Das opções a seguir, qual melhor define seu objetivo de desenvolvimento?',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [profile.goal] },
    kind: 'radio',
    props: {
      // if values change, should change the
      // condition in the `job_selection` node
      options: [
        {
          label: 'Evoluir na carreira',
          value: GOALS.CARRER_DEVELOPMENT,
          icon: assets.floor,
        },
        {
          label: 'Mudança de carreira',
          value: GOALS.CARRER_CHANGE,
          icon: assets.travel,
        },
        {
          label: 'Desenvolvimento pessoal',
          value: GOALS.PERSONAL_DEVELOPMENT,
          icon: assets.psychology,
        },
      ],
    },
    required: true,
  }),

  desired_job: ({ profile }) => ({
    id: QuestionNames.desired_job,
    title: 'Qual cargo você deseja alcançar?',
    kind: 'job_selection',
    countAsStep: false,
    conditions: {
      visible: {
        /**
         * Condicional:
         * Se o objetivo de desenvolvimento for Mudança de carreira Essa questão é habilitada.
         * https://www.figma.com/file/0bYmSUL1u1MUA5nOROaXLN/%5BColab%5D-Mapeamento-de-Interesses?node-id=1355%3A34255&mode=dev
         */
        id: QuestionNames.goals,
        value: GOALS.CARRER_CHANGE,
      },
    },
    props: {
      allowMarkAsOptionNotFound: false,
      optionNotFoundText: 'Não encontrei o cargo quero alcançar.',
      searchHelperText:
        'Selecione a opção que mais se adequa ao seu cargo desejado.',
      searchPlaceholder: 'Procure o cargo desejado',
    },
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [profile.desired_job?.id?.toString()] },
    meta: {
      jobReloadedFromProfile: profile.desired_job,
    },
    required: true,
  }),

  presentation_learn_your_way: () => ({
    id: QuestionNames.presentation_learn_your_way,
    title: 'Aprenda do seu jeito',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: [] },
    kind: 'presentation',
    props: {
      slides: [
        {
          title: 'Aprenda do seu jeito',
          img: assets.learnYourWay,
          subtitle:
            'Selecione as categorias de cursos e os temas que mais te interessam para receber sugestões de conteúdos mais relevantes para você.',
        },
      ],
      buttons: [],
    },
    required: false,
  }),

  select_course_interests: ({ profile, taxonomy }) => {
    const courseInterestOptions = mountInterestOptions(taxonomy);

    return {
      id: QuestionNames.select_course_interests,
      title: 'Quais categorias de curso você tem interesse?',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: profile.course_types || [] },
      kind: 'box_selection',
      props: {
        options: courseInterestOptions,
        validation: {
          maxSelectedCount: courseInterestOptions.length,
          minSelectedCount: 1,
        },
      },
      required: true,
    };
  },
  select_most_interesting_themes: ({ profile, taxonomy }) => {
    const courseInterestOptions = mountInterestOptions(taxonomy);
    const knowledgeAreasOptions = mountKnowledgeAreasOptions(
      taxonomy.knowledge_areas
    );

    return {
      id: QuestionNames.select_most_interesting_themes,
      conditions: {
        visible: {
          id: QuestionNames.select_course_interests,
          value: {
            $in: courseInterestOptions
              .filter((el) => el.thisOptionAllowsSelectThemes === true)
              .map((el) => el.value),
          },
        },
      },
      title: 'E quais temas mais te interessam?',
      touched: false,
      value: [],
      errors: [],
      draftState: {
        value: profile.knowledge_areas?.map((el) => el.id?.toString()) || [],
      },
      kind: 'tag_selection',
      dialogProps: {
        maxWidth: 'sm',
      },
      props: {
        options: knowledgeAreasOptions,
        validation: {
          maxSelectedCount: 5,
          minSelectedCount: 0,
        },
      },
      required: false,
    };
  },

  select_interesting_languages: ({ profile, taxonomy }) => {
    const courseInterestOptions = mountInterestOptions(taxonomy);

    return {
      id: QuestionNames.select_interesting_languages,
      title: 'Quais idiomas você tem mais interesse?',
      conditions: {
        visible: {
          id: QuestionNames.select_course_interests,
          value: {
            $in: courseInterestOptions
              .filter((el) => el.thisOptionAllowsSelectLanguages === true)
              .map((el) => el.value),
          },
        },
      },
      touched: false,
      value: [],
      errors: [],
      draftState: { value: profile.languages || [] },
      kind: 'tag_selection',
      props: {
        options: [
          {
            label: LanguagesLabels[LANGUAGES.FRENCH],
            value: LANGUAGES.FRENCH,
          },
          {
            label: LanguagesLabels[LANGUAGES.ENGLISH],
            value: LANGUAGES.ENGLISH,
          },
          {
            label: LanguagesLabels[LANGUAGES.MANDARIN],
            value: LANGUAGES.MANDARIN,
          },
          {
            label: LanguagesLabels[LANGUAGES.SPANISH],
            value: LANGUAGES.SPANISH,
          },
          {
            label: LanguagesLabels[LANGUAGES.ITALIAN],
            value: LANGUAGES.ITALIAN,
          },
          {
            label: LanguagesLabels[LANGUAGES.JAPANESE],
            value: LANGUAGES.JAPANESE,
          },
          {
            label: LanguagesLabels[LANGUAGES.GERMAN],
            value: LANGUAGES.GERMAN,
          },
          {
            label: LanguagesLabels[LANGUAGES.KOREAN],
            value: LANGUAGES.KOREAN,
          },
          {
            label: LanguagesLabels[LANGUAGES.DUTCH],
            value: LANGUAGES.DUTCH,
          },
          {
            label: LanguagesLabels[LANGUAGES.PORTUGUESE],
            value: LANGUAGES.PORTUGUESE,
          },
        ],
        validation: {
          maxSelectedCount: 5,
          minSelectedCount: 0,
        },
      },
      required: false,
    };
  },

  select_best_learning_modality: ({ profile }) => ({
    id: QuestionNames.select_best_learning_modality,
    title: 'Qual modalidade de ensino se adequa melhor à sua rotina?',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: profile.modalities || [] },
    kind: 'box_selection',
    props: {
      options: labelsToOptions([
        { label: 'Presencial', icon: assets.apartment },
        { label: 'Híbrido', icon: assets.homeWork },
        { label: 'Curso a distância', icon: assets.interactiveSpace },
      ]),
      validation: {
        maxSelectedCount: 3,
        minSelectedCount: 0,
      },
    },
    required: false,
  }),

  select_monthly_budget: ({ profile }) => {
    const budgetOptions = mountBudgetOptions(profile);

    return {
      id: QuestionNames.select_monthly_budget,
      title:
        'Qual é o seu orçamento mensal para investir em seu desenvolvimento?',
      touched: false,
      value: [],
      errors: [],
      kind: 'radio',
      required: false,
      props: { options: budgetOptions.options },
      draftState: {
        value: budgetOptions.initialValue ? [budgetOptions.initialValue] : [],
      },
    };
  },

  time_available_per_week: ({ profile }) => ({
    id: QuestionNames.time_available_per_week,
    title:
      'Quanto tempo você pretende investir no seu desenvolvimento por semana?',
    touched: false,
    value: [],
    errors: [],
    kind: 'radio',
    draftState: {
      value: (() => {
        if (profile.max_hours_available_per_week === 2) return ['0-2'];
        if (profile.max_hours_available_per_week === 5) return ['0-5'];
        if (profile.max_hours_available_per_week === 10) return ['0-10'];
        if (profile.min_hours_available_per_week === 10) return ['10-'];
        return [];
      })(),
    },
    props: {
      options: [
        { label: 'Até 2 horas', value: '0-2' },
        { label: 'Até 5 horas', value: '0-5' },
        { label: 'Até 10 horas', value: '0-10' },
        { label: 'Mais de 10 horas', value: '10-' },
      ],
    },
    required: false,
  }),

  input_contact_email: ({ profile }) => ({
    id: QuestionNames.input_contact_email,
    title: 'Vamos manter contato?',
    touched: false,
    value: [],
    errors: [],
    draftState: { value: profile.email ? [profile.email] : [] },
    kind: 'email_contact',
    props: {
      header:
        'Nos diga seu e-mail preferido para receber informações e novidades sobre a Galena!\n\n' +
        'É para ele que enviaremos as comunicações e instruções sobre suas matrículas e cursos, além de promoções especiais.',
      placeholder: 'Digite o seu e-mail',
    },
    required: false,
  }),

  presentation_finished: () => ({
    title: 'Mapeamos seus interesses!',
    id: QuestionNames.presentation_finished,
    kind: 'presentation',
    value: [],
    countAsStep: false,
    errors: [],
    required: false,
    showFooterActions: false,
    draftState: { value: [] },
    touched: false,
    props: {
      slides: [
        {
          title: 'Mapeamos seus interesses!',
          img: assets.mapInterests,
          subtitle:
            'Agora a sua experiência na Galena será ainda mais personalizada!',
        },
      ],

      buttons: [
        {
          label: 'Explorar a plataforma',
          action: 'exit_quiz',
          kind: 'contained',
        },
      ],
    },
  }),

  presentation_ask_before_close: () => ({
    title: 'Tem certeza que deseja parar de responder ?',
    id: QuestionNames.presentation_ask_before_close,
    conditions: {
      // vai substituir o nó atual quando a pessoa tentar fechar o quiz
      replaceActive: {
        flags: { $elemMatch: 'willClose' }, // verifica se esta tentando fechar
        previousActiveNodeId: {
          $ne: QuestionNames.presentation_finished,
        },
      },
    },
    kind: 'presentation',
    value: [],
    countAsStep: false,
    errors: [],
    required: false,
    draftState: { value: [] },
    touched: false,
    showFooterActions: false,
    props: {
      slides: [
        {
          title: 'Tem certeza que deseja parar de responder ?',
          img: assets.megaphone,
          subtitle:
            'Tem certeza que deseja parar de responder?\n' +
            'Falta pouco para terminar e suas respostas são importantes para fazermos recomendações mais precisas!',
        },
      ],
      buttons: [
        {
          label: 'Continuar respondendo',
          action: 'go_back',
          kind: 'contained',
        },
        {
          label: 'Parar de responder',
          action: 'force_close',
          kind: 'text',
        },
      ],
    },
  }),
};

export function createProfileQuizQuestions(
  props: ResultUseBootstrapData
): QuestionNode[] {
  const { isNewcomer, taxonomy, profile } = props;

  const isEdit = !isNewcomer;

  return filterNull<QuestionNode>([
    isNewcomer && editingProfileSettings.presentation_welcome(),
    isNewcomer && editingProfileSettings.presentation_welcome_step_2(),
    isEdit && editingProfileSettings.presentation_update_preferences(),
    editingProfileSettings.location_info({ profile }),
    editingProfileSettings.scholarity({ profile }),
    editingProfileSettings.current_job({ profile }),
    editingProfileSettings.seniority({ profile }),
    editingProfileSettings.presentation_objective_selection(),
    editingProfileSettings.goals({ profile }),
    editingProfileSettings.desired_job({ profile }),
    editingProfileSettings.presentation_learn_your_way(),
    editingProfileSettings.select_course_interests({
      profile,
      taxonomy,
    }),
    editingProfileSettings.select_most_interesting_themes({
      profile,
      taxonomy,
    }),
    editingProfileSettings.select_interesting_languages({
      profile,
      taxonomy,
    }),
    editingProfileSettings.select_best_learning_modality({ profile }),
    editingProfileSettings.select_monthly_budget({ profile }),
    editingProfileSettings.time_available_per_week({ profile }),
    editingProfileSettings.input_contact_email({ profile }),
    editingProfileSettings.presentation_finished(),
    editingProfileSettings.presentation_ask_before_close(),
  ]);
}

function mountKnowledgeAreasOptions(
  knowledgeAreas: BootstrapInterfaces['knowledgeArea'][]
) {
  return knowledgeAreas.map((el) => ({
    label: el.description_pt_br,
    value: String(el.id),
  }));
}

function mountBudgetOptions(profile: Partial<Profile>) {
  const options = [
    {
      label: `Até ${formatMoney(50, false)}`,
      value: 'null-50.0',
      selected: profile.max_budget_per_month == '50.0',
    },
    {
      label: `Até ${formatMoney(100, false)}`,
      value: 'null-100.0',
      selected: profile.max_budget_per_month == '100.0',
    },
    {
      label: `Até ${formatMoney(300, false)}`,
      value: 'null-300.0',
      selected: profile.max_budget_per_month == '300.0',
    },
    {
      label: `Até ${formatMoney(1000, false)}`,
      value: 'null-1000.0',
      selected: profile.max_budget_per_month == '1000.0',
    },
    {
      label: 'Qualquer valor',
      value: '0-null',
      selected: profile.max_budget_per_month === null,
    },
  ];

  const initialValue = options.find((el) => el.selected)?.value;

  return { options, initialValue };
}

function mountInterestOptions(taxonomy: BootstrapInterfaces['taxonomy']) {
  const extraInfo = {
    languages: { icon: assets.globe, thisOptionAllowsSelectLanguages: true },
    graduation: { icon: assets.graduation, thisOptionAllowsSelectThemes: true },
    postgraduate: { icon: assets.travel, thisOptionAllowsSelectThemes: true },
    free: { icon: assets.autoStories, thisOptionAllowsSelectThemes: true },
    technical: { icon: assets.accountTree, thisOptionAllowsSelectThemes: true },
  };

  return taxonomy.course_types.map((el) => ({
    label: el.label,
    value: el.type,
    ...extraInfo[el.type],
  }));
}

function preloadAssets() {
  if (!IS_BROWSER) return;
  Object.values(assets).forEach((url) => {
    const img = new Image();
    img.src = url;
  });
}
