import type { QuestionNode } from '../quiz.interfaces';
import { keyBy, uniq } from 'powership';

import { reduceQuizState } from './reducer'; // <-- reduceQuizState handles most part of the quiz state logic

import {
  MethodDefinitions,
  QuizStateInit,
  QuizStateValue,
  RuntimeQuestionNode,
} from './interfaces';

import { createState, MiniState } from '../utils/state';
import { fromDraft } from '../utils/fromDraft';
import { IS_LOCALHOST, IS_STAGING_HOST } from '@/utils/ENV';

/**
 * Creates the Quiz State handler
 * The state methods are created bellow (in state.withMethods({ ...methods })),
 * but the logic that runs after any method is called
 * is in the `**reduceQuizState**` function.
 * @param inputConfig
 */

export function createQuizState<
  Extras extends object = { [K: string]: unknown },
>(inputConfig: QuizStateInit<Extras>) {
  const configClone = fromDraft(inputConfig);

  configClone.nodes = removeInvalidInitialValues(configClone.nodes);

  // sync node.value with node.draftState.value
  syncNodeValueAndDrafts(configClone.nodes);

  const { nodes, extraData, activeNodeId } = configClone;

  type StateValue = QuizStateValue<Extras>;

  const initialNodesById: Record<string, QuestionNode> = keyBy(
    nodes,
    (el) => el.id,
    (key) => {
      throw new Error(`Found two nodes with id "${key}"`);
    }
  );

  const initial = reduceQuizState({
    extraData,
    runtimeNodes: fromDraft(nodes) as RuntimeQuestionNode[],
    submittingNodeId: null,
    previousActiveNodeId: null,
    activeNodeId,
    initialNodesById,
    initialNodes: nodes,
    flags: [],
    actionContext: null,
  } as any) as unknown as StateValue;

  assertState(initial, configClone);

  const state = createState(initial) as unknown as MiniState<StateValue>;

  state.connectDevTools('QUIZ');

  state.addMiddleware(function reduceStateAfterEveryChange({ draft, context }) {
    return reduceQuizState({
      extraData, // <- extraData is kept with the initial value
      submittingNodeId: draft.submittingNodeId,
      previousActiveNodeId: draft.previousActiveNodeId,
      activeNodeId: draft.activeNodeId,
      flags: draft.flags,
      runtimeNodes: Object.values(fromDraft(draft.nodeById)),
      initialNodesById,
      initialNodes: nodes,
      actionContext: context,
    });
  });

  function isNodeValid(node: RuntimeQuestionNode) {
    const hasErrors = !!node.errors.length;
    const isEmpty = !node.draftState.value.length;
    const missing = isEmpty && node.required;
    return !(missing || hasErrors);
  }

  const methods: MethodDefinitions<StateValue> = {
    setActiveNodeValue(state, value: string[]) {
      const { activeNodeId } = state;
      if (!activeNodeId) return;
      const node = state.nodeById[activeNodeId];
      node.draftState.value = value;
    },
    setActiveNodeMetadata(state, meta: any) {
      const { activeNodeId } = state;
      if (!activeNodeId) return;
      const node = state.nodeById[activeNodeId];
      node.meta = meta;
    },
    setActiveNodeErrors(state, errors: string[]) {
      const { activeNodeId } = state;
      if (!activeNodeId) return;
      const node = state.nodeById[activeNodeId];
      node.errors = uniq(errors);
    },
    submitActiveNode(state) {
      const { activeNodeId, nodeById } = state;
      if (!activeNodeId) {
        console.warn('!activeNodeId');
        return;
      }

      const activeNode = nodeById[activeNodeId];

      activeNode.touched = true;
      activeNode.value = activeNode.draftState.value;

      if (!isNodeValid(activeNode)) return;

      state.submittingNodeId = activeNode.id;
    },
    goBack(state) {
      const { activeIndex, visibleNodes, previousActiveNodeId } = state;

      const current = visibleNodes[activeIndex];
      const previousNode = visibleNodes[activeIndex - 1];

      if (!previousNode) {
        console.warn('!previousNode');
        return;
      }

      if (current.replaceActive) {
        // when the node is a replacement, we cannot
        // relly on activeIndex, so we use previousActiveNodeId
        state.activeNodeId = previousActiveNodeId;
      } else {
        state.activeNodeId = previousNode?.id ?? null;
      }
    },
    exitQuiz(state) {
      const { hasRequiredNodesEmpty } = state;
      if (hasRequiredNodesEmpty) {
        console.warn('TRYING_TO_CLOSE');
        return;
      }
      if (state.activeNodeId) {
        state.previousActiveNodeId = state.activeNodeId;
      }
      state.flags.push('willClose');
      state.activeNodeId = null;
      state.activeIndex = null;
    },
    addFlags(state, flags: string[]) {
      const flagsSet = new Set([...state.flags]);
      flags.forEach((f) => flagsSet.add(f));
      state.flags = [...flagsSet.values()];
    },
    removeFlags(state, flags: string[]) {
      const flagsSet = new Set([...state.flags]);
      flags.forEach((f) => flagsSet.delete(f));
      state.flags = [...flagsSet.values()];
    },
  };

  return state.withMethods(methods);
}

// sync node.value with node.draftState.value
function syncNodeValueAndDrafts(nodes: QuizStateInit['nodes']) {
  nodes.forEach((node) => {
    if (node.value.length && !node.draftState.value.length) {
      node.draftState.value = [...node.value];
    }

    if (node.draftState.value.length && !node.value.length) {
      node.value = [...node.draftState.value];
    }
  });
}

function assertState(initial: QuizStateValue, config: QuizStateInit) {
  const onError = (error: string) => {
    const msg = `Broken state found.\n${error}`;
    if (IS_LOCALHOST || IS_STAGING_HOST) {
      throw new Error(msg);
    } else {
      console.error(msg);
    }
  };

  // For instance, when a conditional question is not matched anymore, the
  // quiz state will be invalid.
  // In this case, we need a defaultActiveNodeId.
  if (config.defaultActiveNodeId) {
    if (!initial.nodeById[config.defaultActiveNodeId]) {
      onError(
        `The defaultActiveNodeId "${config.defaultActiveNodeId}" is not valid.\n` +
          `Valid values are [${Object.keys(initial.nodeById).join(', ')}]`
      );
    }
  } else {
    onError('No defaultActiveNodeId found.');
  }

  if (config.activeNodeId && !initial.activeNodeId) {
    console.info(
      '⛺︎ Invalid quiz state found️.\n' +
        `   The initial activeNodeId "${config.activeNodeId}" was changed to null during revalidation\n` +
        `   and a rollback to the defaultActiveNodeId "${config.defaultActiveNodeId}" was made.`
    );
    initial.activeNodeId = config.defaultActiveNodeId;
  }
}

function removeInvalidInitialValues(nodes: QuestionNode[]): QuestionNode[] {
  const notNull = (el: any) => el !== undefined && el !== null;

  return nodes.map((node) => {
    const draftHasNonNull = node.draftState.value.some(notNull);
    const valueHasNonNull = node.value.some(notNull);

    return {
      ...node,
      draftState: draftHasNonNull
        ? node.draftState
        : { ...node.draftState, value: [] },
      value: valueHasNonNull ? node.value : [],
    };
  });
}
