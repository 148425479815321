export const locale = {
  lang: 'pt',
  data: {
    COLABORADOR: {
      ALTERAR_SENHA: {
        title: 'Altere a sua senha',
        emptyFields: 'Preencha todos os campos para alterar sua senha.',
        currentPassword: 'Senha atual',
        errorIncorrect: 'Senha incorreta',
        newPassword: 'Nova senha',
        confirmNewPassword: 'Confirmar nova senha',
        errorConfirmPassword:
          'A senha não corresponde com a informada no campo acima',
        btnSave: 'Salvar',
        sucesso: 'Senha alterada com sucesso',
        errorSamePassword: 'A nova senha deve ser diferente da senha atual.',
        errorWeakPassword:
          'Senha muito fraca. Sua senha precisa ter no mínimo 8 caracteres, um número e uma letra.',
      },

      MY_REFUNDS: {
        APPROVED: {
          solicitacoesAprovadas: 'Solicitações aprovadas',
          solicitacoesVazio: 'Nenhuma solicitação aprovada',
          status: 'APROVADO',
          baixarComprovanteStatus: 'Baixar comprovante',
          uploadStatus: 'Upload comprovante',
          header: 'ATENÇÃO',
          temCerteza:
            'Tem certeza que deseja cancelar a solicitação de reembolso?',
          desfazerAcao: 'Não será possível desfazer esta ação.',
          outroPedido: 'Você deverá solicitar outro pedido de reembolso.',
          labelvalorUtilizado: 'Valor utilizado',
          cancelValueError:
            'O valor deve ser menor ou igual que o total do reembolso',
          btnVoltar: 'VOLTAR',
          btnContinuar: 'CONTINUAR',
        },

        BALANCE: {
          naoDefinido: 'Não definido',
          ilimitado: 'Ilimitado',
          meuSaldo: 'Meu saldo',
          nenhumaModalidade: 'Nenhuma modalidade cadastrada',
          modalidadeBeneficio: 'Modalidade de benefício',
          valorTotal: 'Valor total',
          valorConsumido: 'Valor consumido',
          saldoDisponivel: 'Saldo disponível',
        },

        CANCEL_REQUEST: {
          solicitacoesCancelamento: 'Solicitações de cancelamento',
          cancelamentos: 'Cancelamentos',
          nenhumaSolicitacao: 'Nenhuma solicitação de cancelamento',
        },

        CARD: {
          status: 'Status',
          mesReferencia: 'Mês Referencia',
          carregarComprovante: 'Carregar comprovante',
          labelValorTotalComprovante: 'Valor total do Comprovante',
          erroMessage: 'Valor inválido',
          enviarComprovante: 'Enviar comprovante',
          baixarComprovante: 'Baixar comprovante',
          comprovanteEnviado: 'Comprovante já enviado',
          tracking: 'Acompanhar',
          reembolsoAprovado: 'Reembolso Aprovado',
          aguardandoComprovante: 'Aguardando o comprovante',
          analiseDepFin: 'Sob análise do Dep. Financeiro',
          solicitacaoRecebida: 'Solicitação recebida',
          sobAnalise: 'Sob análise da área de learning',
          aprovadoDepLearn: 'Aprovado pelo departamento de Learning',
          aprovadoDepFin: 'Aprovado pelo Dep. Financeiro',
          sobAnaliseRh: 'Sob análise do RH',
          aprovadoGerenteRh: 'Aprovada pelo gerente RH',
          aprovadoGerente: ' Aprovada pelo gerente',
          cancelado: 'Cancelado',
          refundDetail: 'Detalhes da solicitação',
          dataSolicitacao: 'Data da solicitação',
          valorTotalCurso: 'Valor total do curso',
          installmentsNumber: 'Número de parcelas',
          reembolsoMensal: 'Reembolso mensal (incluir este valor no NOW)',
          reembolsoTotal: 'Reembolso total',
          valorCreditoConsumido: 'Valor de crédito consumido:',
          cancelarSolicitacao: 'CANCELAR SOLICITAÇÃO',
        },

        COMPLETED: {
          solicitacoesFinalizadas: 'Solicitações de reembolso finalizadas',
          solicitacoesFinalizadasVazio: 'Nenhum reembolso finalizado',
          finalizados: 'FINALIZADOS',
        },

        NAVIGATION: {
          tituloBanner: 'Reembolsos',
          pending: 'Em andamento',
          overview: 'Todos',
        },

        OVERVIEW: {
          newRefund: 'Novo reembolso',
          tableDate: 'Data de solicitação',
          tableCourse: 'Nome do curso',
          tableCourseAmount: 'Valor total do curso',
          tableRefundAmount: 'Reembolso total',
          tableStatus: 'Status',
          labelStatus: 'Status',
          labelPeriodo: 'Período',
          btnClearFilters: 'Limpar filtros',
          btnExportar: 'Filtrar seleção',
          budget: 'Saldo disponivel:',
        },

        PENDING: {
          sobAnalise: 'Sob análise da área de learning',
          sobAnaliseRh: 'Sob análise do RH',
          requestReview: 'Solicitações de reembolso em análise',
          noRequestReview: 'Nenhum reembolso em análise',
          sure: 'Tem certeza que deseja cancelar a solicitação de reembolso?',
          undo: 'Não será possível desfazer esta ação.',
          anotherRequest: 'Você deverá solicitar outro pedido de reembolso.',
          back: 'VOLTAR',
          continue: 'CONTINUAR',
        },

        RECEIPTS: {
          meusRecebimentos: 'Meus recebimentos',
          mes: 'Mês',
          valor: 'Valor',
          curso: 'Curso',
        },

        REFUND_CARD: {
          status: 'Status',
          date: 'Data da solicitação:',
          courseAmount: 'Valor total do curso:',
          requestedRefund: 'Reembolso solicitado:',
          approvedCourse: 'Eba! Esse curso foi aprovado pel o seu RH!',
          requestInvoice:
            'Insira abaixo o comprovante para receber o reembolso',
          loadInvoice: 'Carregar comprovante',
          sendInvoice: 'Enviar comprovante',
          cantCancel:
            'Na etapa financeira não é possível solicitar o cancelamento do reembolso.',
          canCancel:
            ' Você ainda pode cancelar a solicitação de aprovação do curso.',
          cancelRequest: 'CANCELAR SOLICITAÇÃO',
        },
      },

      PRE_ENROLLMENT: {
        NAVIGATION: {
          tituloBanner: 'Minhas pré-matrículas',
          active: 'Ativas',
          payments: 'Pagamentos Pendentes',
          notfinalized: 'Não Finalizadas',
          canceled: 'Canceladas',
        },

        ACTIVE: {
          preEnrollments: 'Acompanhe aqui as suas pré-matrículas ativas',
          labelBusqueCurso: 'Busque por um curso',
          labelSearchInstitution: 'Busque por uma instituição',
        },

        CANCELED: {
          preEnrollments: 'Acompanhe aqui as suas pré-matrículas canceladas',
          labelBusqueCurso: 'Busque por um curso',
          labelSearchInstitution: 'Busque por uma instituição',
        },

        NOTFINALIZED: {
          preEnrollments:
            'Acompanhe aqui as suas pré-matrículas não finalizadas',
          labelBusqueCurso: 'Busque por um curso',
          labelSearchInstitution: 'Busque por uma instituição',
        },

        PAYMENT: {
          preEnrollments:
            'Acompanhe aqui as suas pré-matrículas com pagamento pendente',
          labelBusqueCurso: 'Busque por um curso',
          labelSearchInstitution: 'Busque por uma instituição',
        },

        CARD: {
          courseDetails: 'Detalhes do curso',
          importantInfo: 'Informações importantes',
          de: 'De',
          por: 'Por',
        },
      },

      PESQUISA_PERFIL: {
        PESQUISA_SAUDACAO: {
          saudacao: 'Olá, ',
          greetings: 'Que bom que você chegou!',
          beneficio:
            'A Galena é o benefício em educação que te dá acesso a centenas de instituições de ensino e +150 mil cursos.',
          opcoes:
            'Isso mesmo, são muitas opções! E para te ajudar a encontrar o curso certo, que atenda suas necessidades e seu momento de vida, precisamos que você nos ajude com algumas informações.',
          promise: 'Prometemos que vai ser rapidinho!',
          btnComecar: 'QUERO COMEÇAR A PESQUISA',
        },
        PAGINA_B: {
          categoria:
            'Primeiro, nos diga qual, ou quais, categorias de cursos mais te interessam.',
          recomendacao:
            'Assim poderemos recomendar os melhores cursos para você.',
          btnPular: 'PULAR PERGUNTA',
          btnContinuar: 'CONTINUAR',
        },
        PESQUISA_IDIOMAS: {
          selecioneIdioma: 'Selecione o(s) idioma(s) do seu interesse.',
          required: 'Resposta Obrigatória',
          btnPular: 'PULAR PERGUNTA',
          btnContinuar: 'CONTINUAR',
        },
        PESQUISA_GRADUACAO: {
          selecioneGraduacao:
            'Selecione os cursos que você tem interesse em se desenvolver.',
          interesseGraduacao: 'Digite um curso de interesse',
          btnPular: 'PULAR PERGUNTA',
          btnContinuar: 'CONTINUAR',
        },
        PESQUISA_POS_GRADUACAO: {
          selecionePos:
            'Selecione os cursos que você tem interesse em se desenvolver.',
          interessePos: 'Digite um curso de interesse',
          btnPular: 'PULAR PERGUNTA',
          btnContinuar: 'CONTINUAR',
        },
        PAGINA_F: {
          NIVEL: {
            infantil: 'Ensino Infantil',
            fundamental: 'Ensino Fundamental',
            medio: 'Ensino Médio',
          },
          TIPO: {
            particular: 'Particular',
            publica: 'Pública',
          },

          DEPENDETES_OPTIONS: {
            yes: 'Sim',
            no: 'Não',
          },
          btnPular: 'PULAR PERGUNTA',
          chooseOption: 'Escolha uma opção',
          dependentesIdadeEscolar: 'Possui dependentes em idade escolar?',
          perguntaEscola: 'O seu filho estuda em escola particular ou pública?',
          aviso: 'Resposta Obrigatória',
          selecaoTipo: 'Selecione o tipo de escola',
          perguntaNivel: 'Em quais níveis escolares os seus filhos estão?',
          selecaoNivel: 'Selecione os níveis',
          perguntaValor:
            'Qual opção de valor abaixo você pagaria como mensalidade para a escola do seu filho?',
          cep: 'Nos informando o seu CEP, podemos enviar recomendações de escolas disponíveis em sua cidade!',
          perguntaCep: 'Qual é o seu CEP?',
          erroCep: 'CEP inválido!',
        },
        PAGINA_G: {
          selecioneDesenvolvimento:
            'Selecione cursos de interesse de Desenvolvimento Pessoal.',
          interesseDesenvolvimento: 'Digite um curso de interesse',
          btnPular: 'PULAR PERGUNTA',
          btnContinuar: 'CONTINUAR',
        },
        PESQUISA_DADOS_PESSOAIS: {
          introducao:
            'Antes de começarmos, gostaríamos de fazer umas perguntas para ajudar você a encontrar as melhores oportunidades',

          escolaridade: 'Qual sua escolaridade?',
          selecioneEscolaridade: 'Selecione sua escolaridade',

          profissao: 'Qual sua profissão?',
          digiteProfissao: 'Digite sua profissão',

          cargo: 'Qual o seu cargo atual?',
          digiteCargo: 'Digite o cargo',

          contato:
            'Nosso papel é te informar sobre as melhores ofertas e oportunidades nas nossas instituições. Podemos te mandá-las por e-mail?',

          emailDescription: 'Informe seu email',
          digiteEmail: 'Digite o email',

          phoneDescription: 'Informe seu telefone com DDD (Apenas números)',
          digiteTelefone: 'Digite o telefone',

          cpf: 'Gostaria de informar seu cpf?',
          digiteCpf: 'Digite o cpf',

          genero: 'Poderia informar sua identidade de gênero?',
          digiteGenero: 'Digite o gênero',

          btnPular: 'PULAR PERGUNTA',
          btnContinuar: 'CONTINUAR',
        },
        PAGINA_I: {
          contact:
            'Podemos entrar em contato quando tivermos descontos super especiais em cursos recomendados para você?',
          phone: 'Digite o seu telefone.',
          btnFinish: 'FINALIZAR',
        },
        PESQUISA_COMPLETE: {
          betterOptions: 'Obrigado por responder a pesquisa.',
          accessMessage:
            'Agora sabemos o que você está procurando e podemos te auxiliar na busca do melhor curso para seu momento de carreira e vida pessoal!',
          accessEdupass: 'EXPLORE A Galena',
        },
        PAGINA_K: {
          dependentes: 'Galena PARA DEPENDENTES',
          ensinoBasico:
            'Você sabia que a Galena tem uma categoria de escolas de Ensino Básico?',
          interesse: 'TENHO INTERESSE',
        },

        CATEGORIAS: {
          ensinoBasico: {
            title: 'Ensino Básico / Pré-Vestibular',
            descricao:
              'Descontos em escolas de ensino básico, cursos para crianças e adolescentes e preparatórios para o vestibular.',
          },

          graduacao: {
            title: 'Graduação',
            descricao:
              'Descontos para Bacharelados, Tecnólogos e Licenciaturas, com opções de ensino remoto, híbrido ou presencial.',
          },

          idiomas: {
            title: 'Idiomas',
            descricao:
              'Descontos para aprender até 6 diferentes idiomas em escolas renomadas ou com professores particulares.',
          },

          pos: {
            title: 'Pós-Graduação',
            descricao:
              'Descontos para Especializações e MBA com opções de ensino remoto, híbrido ou presencial.',
          },

          tech: {
            title: 'Tecnologia & Digital',
            descricao:
              'Descontos para cursos de tecnologia e transformação digital, assim como UX/UI, Linguagem de Programação, Gestão de Projetos, entre outras hard skills.',
          },

          tecnicos: {
            title: 'Cursos Técnicos/ Micro Certificações',
            descricao:
              'Descontos para cursos técnicos e especializações em diferentes áreas de ensino como Eletrotécnica, Mecânica, Logística, Marketing, entre outros.',
          },

          curta: {
            title: 'Desenvolvimento Pessoal',
            descricao:
              'Cursos para aprimorar suas habilidades técnicas e conhecimentos socioemocionais em diferentes áreas como Gestão de Contratos, Transformação Ágil, Educação Financeira, Segurança do Trabalho e muito mais, com bolsas de até 100% de desconto!',
          },
        },

        LANGUAGES: {
          english: 'Inglês',
          spanish: 'Espanhol',
          french: 'Francês',
          italian: 'Italiano',
          german: 'Alemão',
          portuguese: 'Português para Estrangeiros',
        },

        GRADUATION_HIGHLIGHTS: {
          admin: 'Administração / Economia',
          engineering: 'Engenharias',
          computer_science: 'Ciências da Computação e Informação',
          finance: 'Contabilidade/ Finanças',
          marketing: 'Comunicação/ Publicidade/ Marketing',
          management: 'Gestão e Empreendedorismo',
          logistics: 'Logística',
          health: 'Saúde e Biologia',
          human_science: 'Ciências Humanas',
          graduations: 'Licenciaturas',
          operations: 'Operações (Segurança do Trabalho, …)',
        },

        POS_GRADUATION_HIGHLIGHTS: {
          tecnologia: 'Tecnologia',
          business: 'Negócios',
          finance: 'Finanças',
          human_resources: 'Recursos Humanos',
          leadership: 'Liderança',
          marketing: 'Marketing',
          project_management: 'Gestão de Projetos',
          inovation: 'Inovação',
        },

        DESENVOLVIMENTO_PESSOAL: {
          negotiation: 'Negociação',
          leadership: 'Liderança',
          project_management: 'Gestão de projetos',
          logistics: 'Logística',
          workplace_safety: 'Segurança do trabalho',
          bi: 'BI',
        },

        ORCAMENTO: {
          valor1: 'Até R$ 250,00',
          valor2: 'De R$ 250,00 a R$ 500,00',
          valor3: 'De R$ 500,00 a R$ 750,00',
          valor4: 'De R$ 750,00 a R$ 1.000,00',
          valor5: 'Acima de R$ 1.000,00',
        },

        PAGE: {
          titulo1: 'Categorias de cursos',
          titulo2: 'Idioma de interesse',
          titulo3: 'Área de interesse de Graduação',
          titulo4: 'Área de interesse de Pós-graduação',
          titulo5: 'Interesse em Ensino Básico',
          titulo6: 'Interesse em Desenvolvimento Pessoal',
          titulo7: 'Análise de perfil',
          titulo8: 'Telefone de contato',
        },
      },

      REFUND_FORM: {
        novaSolicitacao: 'Novo reembolso',
        courseData: 'Informações do curso',
        isEdupass: 'O curso foi comprado pela Galena?',
        EDUPASS_OPTIONS: {
          yes: 'Sim',
          no: 'Não',
        },
        perguntaNomeFornecedor: 'Qual é o nome da instituição?',
        perguntaNomeCurso: 'Qual é o nome do curso?',
        valorCurso: 'Valor cobrado (R$)',
        perguntaValorReembolso: 'Valor do reembolso (R$)',
        reasonPlaceholder:
          '”Comprei o curso pois estava no meu plano de desenvolvimento”',
        paymentInfo: 'Informações de pagamento',
        perguntaFormaPagamento: 'Qual é a forma de pagamento?',
        perguntaNome: 'Nome',
        perguntaCPF: 'CPF',
        perguntaPix: 'Chave PIX',
        bank: 'Número do Banco',
        agencia: 'Agência',
        numeroConta: 'Número de conta e dígito',
        justificativa: 'Justificativa (opcional)',
        btnEnviar: 'Enviar Solicitação',
      },

      INITIAL_RESEARCH: {
        FIRST_PAGE: {
          hello: 'Olá ',
          salution:
            '! Que prazer te ver dando início ao seu benefício de educação! Vamos juntos iniciar sua jornada?',
          weEdupass:
            'Nós somos a Galena, um benefício de educação que te dá acesso a centenas de instituições de ensino e +150 mil cursos!',
          help: 'É bastante coisa, né? Não se preocupe. Estamos aqui para poder indicar as melhores opções para você.',
          letsMeet: 'Para que isso aconteça, vamos nos conhecer melhor?',
          start: 'Quero começar',
        },

        LOCATION: {
          aboutYou: 'Sobre você',
          letsStart: 'Então vamos começar! ',
          country: 'Você mora no Brasil?',
          countryPlaceholder: 'Selecione aqui',
          state: 'Em qual Estado você mora?',
          statePlaceholder: 'Selecione aqui',
          city: 'Em qual cidade você mora?',
          cityPlaceholder: 'Selecione aqui',
          back: 'Voltar',
          next: 'Avançar',
        },

        QUALIFICATION: {
          aboutYou: 'Sobre você',
          trajectory: 'Legal! Que tal nos contar da sua trajetória até aqui?',
          formation: 'Qual é a sua formação atual?',
          back: 'Voltar',
          next: 'Avançar',
        },

        SOBRE_VOCE_THREE: {
          aboutYou: 'Sobre você',
          completedGraduation:
            'Que legal! Parabéns por ter completado sua graduação, imaginamos que foram anos incríveis da sua vida!',
          howLong: 'Há quanto tempo você é formado(a)?',
          back: 'Voltar',
          next: 'Avançar',
        },

        CURRENT_ROLE: {
          aboutMoment: 'Sobre seu momento',
          carreerMoment:
            'Certo, agora nos conte sobre seu momento atual de carreira!',
          currentRole:
            'Das opções abaixo, qual define melhor o seu cargo atual?',
          back: 'Voltar',
          next: 'Avançar',
        },
        SOBRE_MOMENTO_TWO: {
          aboutMoment: 'Sobre seu momento',
          thinkMoment:
            'Muito bom saber sobre sua área de atuação! Mas, conta pra gente, pensando na sua atuação e momento atual.',
          changeCarreer:
            'Você pretende realizar uma transição de carreira ou começar a se capacitar em outra área?',
          back: 'Voltar',
          next: 'Avançar',
        },
        SOBRE_MOMENTO_THREE: {
          aboutMoment: 'Sobre seu momento',
          thinkMoment:
            'Que legal! Entendemos o quanto é importante olhar para outras áreas e buscar oportunidades que talvez façam mais sentido!',
          changeCarreer:
            'Em qual(is) área(s) você possui interesse para começar a se capacitar, ou então, fazer uma transição de carreira?',
          multipleOptions: 'Você pode selecionar mais de uma opção (: ',
          back: 'Voltar',
          next: 'Avançar',
        },

        CAREER_FIELD: {
          aboutMoment: 'Sobre seu momento',
          carreerMoment:
            'Certo, agora nos conte sobre seu momento atual de carreira!',
          currentPost:
            'Dentro da empresa que você trabalha, qual é a sua área de atuação?',
          back: 'Voltar',
          next: 'Avançar',
        },

        INTERESTS: {
          interests: 'Seus interesses',
          courseInterest:
            'Agora que você contou mais sobre a(s) área(s) de interesse, é importante sabermos, na sua visão, qual é o curso que faz mais sentido pra você.',
          courseLike: 'Que tipo de curso você gostaria de fazer?',
          multipleOptions: 'Você pode selecionar mais de uma opção (:',
          back: 'Voltar',
          next: 'Avançar',
        },
        INTERESTS_TECH: {
          intersts: 'Seus interesses',
          techDigital:
            'Que interessante, agora pensando no assunto “Tecnologia & Digital”',
          subjects: 'Qual(is) assunto(s) mais te interessam?',
          multipleOptions: 'Você pode selecionar mais de uma opção (:',
          back: 'Voltar',
          next: 'Avançar',
        },
        INTERESTS_LANGUAGE: {
          intersts: 'Seus interesses',
          languageThink: 'Que interessante! Pensando no assunto “Idiomas”',
          subjects: 'Qual(is) idioma(s) você possui maior interesse?',
          multipleOptions: 'Você pode selecionar mais de uma opção (:',
          back: 'Voltar',
          next: 'Avançar',
        },
        INTERESSES_FOUR: {
          intersts: 'Seus interesses',
          budget:
            'Agora vamos tratar de um assunto muito importante, pois conforme sua resposta poderemos direcionar as melhores oportunidades que cabem no seu bolso!',
          limitBudget:
            'Até quanto você gostaria de investir na sua carreira mensalmente?',
          back: 'Voltar',
          next: 'Avançar',
        },

        INTERESTS_POST_GRADUATION: {
          intersts: 'Seus interesses',
          subjects:
            'Pensando em pós-graduação, em qual(is) área(s) você possui maior interesse?',
          multipleOptions: 'Você pode selecionar mais de uma opção (:',
          back: 'Voltar',
          next: 'Avançar',
        },

        INTERESTS_GRADUATION: {
          intersts: 'Seus interesses',
          subjects:
            'Pensando em graduação, em qual(is) área(s) você possui maior interesse?',
          multipleOptions: 'Você pode selecionar mais de uma opção (:',
          back: 'Voltar',
          next: 'Avançar',
        },

        MODALITY: {
          interest: 'Seus interesses',
          modality:
            'Qual modalidade de ensino se adequa melhor à sua realidade atual?',
          back: 'Voltar',
          next: 'Avançar',
        },

        DISCOUNTS: {
          title: ' Descontos e oportunidades exclusivas!',
          emailError: 'E-mail inválido',
          edupassExplanation:
            'Na Galena, estamos sempre buscando as melhores oportunidades de estudo para você! Caso você não queira ficar de fora das melhores atualizações sobre bolsas de estudo, descontos e conteúdos exclusivos, nos informe o seu melhor contato, pode ser e-mail, telefone ou os dois!',
          sendResponse: 'Enviar respostas',
        },
        LOADING: {
          waitAMoment: 'Só um minutinho...',
          analysing:
            'Estamos analisando as informações para trazer as melhores oportunidades de desenvolvimento pra você!',
        },
        FINAL: {
          thanks: 'Agradecemos sua participação!',
          thank_you: 'Obrigado pela sua participação!',
          important:
            'É muito importante para nós saber mais sobre você e seus interesses.',
          access: 'Acessar Galena',
        },
      },
    },

    CORPORATE_UNIVERSITY: {
      NAVIGATION: {
        CATALOG: 'Catálogo de Trilhas',
        PENDING: 'Cursos em andamento',
        COMPLETE: 'Cursos concluídos',
      },

      CORPORATE_UNIVERSITY_PANEL: {
        salutation: 'Olá,',
        title: 'Escolha os cursos da Tópico.',

        PENDING: {
          progress: 'Progresso',
          continuarCurso: 'CONTINUAR CURSO',
          nenhumCurso: 'Você não tem nenhum curso em andamento',
          explore: 'Explore o catálogo de trilhas',
          catalogo: 'Ir para catálogo',
        },
        COMPLETE: {
          accessCertificate: 'ACESSAR CERTIFICADO',
          nenhumCursoConcluido: 'Você não tem nenhum curso concluído',
          explore: 'Explore o catálogo de trilhas',
          catalogo: 'Ir para catálogo',
        },
      },

      CORPORATE_UNIVERSITY_COURSES: {
        courseDetails: 'teste',
        importantInfo: 'teste',
        back: 'Voltar para catálogo de Trilhas',
        nenhumCurso: 'Não encontramos nenhum curso',
        explore: 'Explore o nosso catálogo',
        catalogo: 'Ir para catálogo',
      },

      CORPORATE_UNIVERSITY_VIDEOS: {
        visao: 'Acompanhe as aulas do curso',
        progresso: 'Progresso:',
        btnAnterior: 'Anterior',
        btnProximo: 'Próximo',
        btnVerCertificado: 'Baixar certificado',
        openExam: 'Realizar avaliação',
        downloadContent: 'Fazer download do material complementar',
      },
    },

    CURSOS: {
      CHECKOUT: {
        TERMS_CONDITIONS: {
          instructions: 'Para prosseguir, siga as instruções abaixo:',
          alreadySubscribed: 'Você já estuda na',
          yes: 'Sim',
          no: 'Não',
          confirmTerms: 'Termo de compromisso e condições da bolsa.',
          read_carefully_and_confirm:
            'Leia atentamente e confirme abaixo para prosseguir.',
          deAcordo: 'Li e estou de acordo com as condições da bolsa',
          confirm: 'Confirmar',
          ohNo: 'Essa não! :(',
          unavailableMessage:
            'Infelizmente você não pode fazer este curso, pois ele é apenas para novos alunos na instituição',
          close: 'Fechar',
        },

        SUMMARY: {
          summary: 'Resumo do curso',
          modality: 'Modalidade',
          duration: 'Duração',
        },

        CREDIT_CARD_REFUSE: {
          request_card_holder_name: {
            You_may_not_use_numbers_or_special_characters:
              'Você não pode usar números ou caracteres especiais no "Nome do titular".',
          },
          request_card: {
            The_number_field_is_not_a_valid_card_number:
              'O campo número não é um número de cartão válido.',
            Card_expired: 'Cartão expirado.',
            The_card_expiration_date_is_invalid:
              'A data de validade do cartão é inválida.',
          },
          request_card_exp_month: {
            The_field_exp_month_must_be_between_1_and_12:
              "O campo 'Mes de validade' deve estar entre 1 e 12.",
          },
          request_card_cvv: {
            The_field_cvv_must_be_a_string_with_a_minimum_length_of_3_and_a_maximum_length_of_4:
              "O campo 'CVV' deve ser uma string com um comprimento mínimo de 3 e um comprimento máximo de 4.",
            The_cvv_field_is_not_a_valid_number:
              'O campo "CVV" não é um número válido.',
          },
        },

        REGISTRATION: {
          insertCoupon: 'Insira seu cupom aqui',
          invalidCoupon: 'Cupom inválido',
          applyCoupon: 'Aplicar cupom',
          coupon: 'Cupom de desconto',
          appliedCoupon: 'Cupom aplicado (+',
          appliedCoupon2: '% de desconto)',
          removeCoupon: 'Remover cupom',
        },
      },

      CURSOS_DETALHES: {
        desconto: '% de desconto',
        garantirBolsa: 'GARANTIR BOLSA',
        adquiraJa: 'ADQUIRA JÁ',
        garantaPreMatricula: 'GARANTA PRÉ-MATRÍCULA',
        bolsaGarantida: 'BOLSA JÁ GARANTIDA',
        sobre: 'Sobre este curso',
        values:
          'Os valores e porcentagens das bolsas são negociados com a instituição à partir do momento da inscrição. ',
        preEnrollment:
          ' Sendo assim, ao clicar em “Garantir Bolsa” você está realizando sua pré-inscrição no curso, podendo este variar o valor conforme a negociação vigente.',
        noWorries:
          'Não se preocupe! Nosso time de atendimento irá te passar todos os valores corretos e auxiliar no seu processo de matrícula! Para prosseguir, basta clicar em “continuar”.',
        duracao: 'Duração do curso',
        modalidade: 'Modalidade',
        labelDesconto: 'Desconto',
        economize: 'Economize!',
        pague: 'Pague:',
        economizeAte: 'Economize até:',
        instituicao: 'Sobre a Instituição',
        simulador: 'Simulador',
        entenda: 'Entenda o que você vai pagar',
        mensalidade: 'a mensalidade',
        valor: 'o valor',
        cursoDe: 'normal do curso de',
        na: 'na',
        qualDesconto: 'Qual é o seu desconto Galena',
        pagara: 'Você pagará',
        de: 'de',
        fim: 'Economize até o fim do curso:',
        course_gift:
          ' Agora você pode dar este curso de presente! É muito simples, basta informar os dados de quem irá receber o curso, realizar o pagamento e prontinho!',
        course_gift_form:
          'Insira abaixo as informações de quem irá receber o presente.',
        course_gift_form_2:
          ' Por favor, preencha as informações abaixo para entregarmos o seu presente.',
        compartilharCurso: 'Compartilhar curso',
        howItWorks: 'Como funciona a Galena?',
        simple:
          'Estudar pela Galena é simples e descomplicado, você só precisa seguir os passos abaixo e começar a mudança de vida através da educação!',

        IDIOMAS_CURSOS_DETALHES: {
          matricular: 'Matricule-se já',
          adquira: 'Adquira já',
          compartilharCurso: 'Compartilhar curso',
          razaoCurso: 'Porque fazer o curso',
          sobreInstituicao: 'Sobre a instituição',
          porqueEstudarEdupass: 'Por que estudar com a Galena',
          porqueEdupassResposta:
            'Com a Galena, você tem acesso a ofertas e descontos exclusivos! Confira abaixo a condição exclusiva que você só encontra na nossa plaforma. Agora não tem mais desculpa para deixar de estudar, não é mesmo?',
          valorTotalCurso: 'Valor total do curso',
          valorTotalSemEdupass: 'sem',
          valorTotalComEdupass: 'com',
          voceEconomiza: 'Economia estimada de até:',
          comoPagar: 'Como posso pagar este curso?',
          melhorManeiradePagar:
            'A melhor maneira para pagar o curso é aquela que cabe no seu bolso! Na hora do pagamento, você pode selecionar as seguintes opções:',
          cartaoDeCredito: 'Cartão de crédito',
          cartaoDeCreditoDescription: '(valor total em até ',
          cartaoDeCreditoDescription2: 'x sem juros)',
          boletoBancario: 'Boleto Bancário',
          boletoBancarioDescription: '(valor total com 5% de desconto)',
          pix: 'Pix',
          pixDescription: '(valor total com 10% de desconto)',
          ticket: 'Ticket Super Flex',
          cursosSimilares: 'Quem viu esse curso tabém se interessou por:',
        },
      },

      CURSOS_GALLERY: {
        graduacao: 'Graduação',
        pos_graduacao: 'Pós Graduação',
        idiomas: 'Idiomas',
        tecnologia: 'Tech & Digital',
        desenvolvimento_pessoal: 'Desenvolvimento Pessoal',
        lideranca: 'Pré-Vestibular',
        hobbies: 'Ensino Básico',
        eduplay: 'Eduplay',
        'meus-cursos': 'Recomendações para o meu perfil',
        kids: 'Para crianças e adolescentes',
        tecnicos: 'Técnicos',
        curta_duracao: 'Curta Duração',
        lideranca_gestao: 'Gestão',

        CATEGORIA_BANNER: {},
        FILTROS: {
          FILTRO_ITEM: {
            ITEMS: {
              ORCAMENTO: {
                button: 'Orçamento',
              },
              CIDADE: {
                button: 'Cidade',
                placeholder: 'Digite a cidade que procura',
              },
              CURSO: {
                button: 'Curso',
                placeholder: 'Digite o curso que procura',
              },
              ESTADO: {
                button: 'Estados',
                placeholder: 'Digite o estado que procura',
              },
              IDIOMA: {
                button: 'Idioma',
              },
              INSTITUICAO: {
                button: 'Instituição',
                placeholder: 'Digite a instituição que procura',
              },
              MEU_CURSOS: {
                button: 'Categorias',
              },
              MODALIDADE: {
                button: 'Modalidade',
              },
              SUBCATEGORIA: {
                button: 'Subcategoria',
              },
            },
          },

          filter: 'Filtrar por:',
          limparFiltro: 'Limpar Filtros',
        },

        cursosDe: 'cursos de',
        selecionados: 'selecionados',
        naoEncontrou: 'Não encontrou o que procurava?',
        indicarCurso: 'Indique um curso ou insituição.',
        indicar: 'Indicar',
        mostrarMais: 'Mostrar mais cursos',
        indicacaoHeader: 'Indique um curso, insituição e/ou localidade',
        indicarDetalhe:
          'Indique um curso, insituição e/ou localidade de seu interesse',
        indicacaoPlaceholder:
          'Digite o que procura, que iremos atrás para você.',
        indicacaoEnviar: 'Enviar',
        indicacaoCancelar: 'Cancelar',
      },

      CURSOS_PESQUISA: {
        resultado:
          'Encontramos os seguintes resultados para a sua pesquisa por:',

        mostrarMais: 'Mostrar mais cursos',
        resultadoNegativo: 'Resultado para:',
        ohNo: 'Ah não! Ainda não temos o curso',
        lookingFor: 'que você procura :(',
        contactManager:
          'Entre em contato com o RH da sua empresa e solicite a indicação do curso.',
      },

      CUSTOM_CATEGORY: {
        selected:
          'Confira abaixo os cursos escolhidos pelos gestores da sua empresa',
      },

      PRE_MATRICULA: {
        safePage: 'Ambiente seguro',
        etapaTitle: 'Finalize sua pré-matrícula',
        sellTitle: 'Finalize sua compra',
        labelEtapa: 'Etapa',
        etapaDe: 'de',
        bolsa: 'Bolsa de',
        de: 'De',
        por: 'Por',
        detalhe: 'Detalhes do curso',
        informacao: 'Informações importantes',
      },

      PRE_MATRICULA_UM: {
        labelTopbar: 'Ambiente seguro',
        etapaTitle: 'Finalize sua pré-matrícula',
        labelEtapa: 'Etapa 1 de 3',
        labelForm: 'Dados Gerais',
        labelNome: 'Nome Completo:',
        labelDataNascimento: 'Data de nascimento:',
        labelCelular: 'Celular:',
        perguntaAluno: 'Você já é aluno da',
        btnSim: 'Sim',
        btnNao: 'Não',
        btnEnviar: 'Enviar',
        bolsa: 'Bolsa de',
        periodo: 'Turno:',
        de: 'De',
        por: 'Por',
        detalhe: 'Detalhes do curso',
        informacao: 'Informações importantes',
        manha: 'Matutino',
        tarde: 'Vespertino',
        noite: 'Noturno',
        emptyField: 'Campo obrigatório',
        alreadySubscribed:
          'Só é permitida uma bolsa por instituição, entre em contato para mais informações',
      },

      PRE_MATRICULA_DOIS: {
        labelTopbar: 'Ambiente seguro',
        etapaTitle: 'Finalize sua pré-matrícula',
        labelEtapa: 'Etapa 2 de 3',
        labelForm: 'Contato',
        labelCep: 'CEP:',
        erroCep: 'CEP inválido',
        labelEstado: 'Estado:',
        labelCidade: 'Cidade:',
        labelEndereco: 'Endereço',
        labelBairro: 'Endereço',
        labelNumero: 'Número',
        labelComplemento: 'Complemento',
        labelOpcional: '(Opcional)',
        condicoes: 'Condições da bolsa',
        deAcordo: 'Li e estou de acordo com as condições da bolsa',
        periodo: 'Turno:',
        de: 'De',
        por: 'Por',
        detalhe: 'Detalhes do curso',
        informacao: 'Informações importantes',
        btnPagamento: 'Enviar',
        requiredField: 'Campo obrigatório',
        termsError: 'Você precisa aceitar as condições para prosseguir',
        cupom: 'Tem um cupom de desconto?',
        cupomSubtitle:
          'Informe aqui o cupom de desconto fornecido pela sua empresa',
        alreadySubscribed:
          'Ops...você não pode ter mais de uma bolsa em uma mesma instituição',
        alreadySubscribedEmpty:
          'Você precisa informar se já é aluno da instituição',
      },

      PRE_MATRICULA_TRES: {
        labelTopbar: 'Ambiente seguro',
        etapaTitle: 'Finalize sua pré-matrícula',
        labelEtapa: 'Etapa 3 de 3',
        labelForm: 'Pagamento',
        direcionado: 'Você está sendo direcionado para a página de pagamento',
      },

      PRE_MATRICULA_SUCESSO: {
        labelTop: 'Parabéns!',
        labelTopText: 'Seu pagamento foi aprovado!',

        inscricao: 'Lembre-se de realizar a sua inscrição na Instituição',
        inscricaoEduplay:
          'Sua inscrição já foi realizada e agora você pode assistir aos vídeos diretamente na plataforma',

        labelPending: 'Falta pouco',
        pendingPayment: 'Finalize o pagamento de sua pré-matrícula',
        labelTextPendingPix: 'O seu código PIX expira em',
        labelTextPendingPixMinutes: 'minutos',
        labelTextPendingBoleto: 'Você tem',
        labelTextPendingBoletoDays: 'dias para pagar o seu boleto',
        descriptionPending: 'Clique no botão abaixo para finalizar o pagamento',
        descriptionPendingPix:
          'Escaneie o QR Code abaixo para finalizar o pagamento',
        labelProcessing: 'Falta pouco',
        labelTextProcessing: 'O seu pagamento está sendo processado',
        descriptionProcessing:
          'Clique no botão abaixo para acompanhar o status do seu pedido',
        labelReproved: 'Ops',
        labelTextReproved: 'O seu pagamento não foi aprovado',

        click:
          'Clique no botão abaixo para ir para a página do parceiro, e realizar a sua matrícula.',
        clickEduplay: 'Clique no botão abaixo para acessar o curso.',

        btnSite: 'Ir para o site da instituição',
        btnSiteEduplay: 'Acessar o curso',

        btnStatus: 'Acompanhar status do pedido',
        completePixPayment: 'Finalizar pagamento',
        completeBoletoPayment: 'Finalizar pagamento',
        btnRetry: 'Comprar novamente',
        eduplay: 'Você já conhece a Eduplay?',
        eduplayText: 'A Eduplay é a produtora de conteúdo original da Galena.',
        dezenas: 'São dezenas de',
        cursos: 'cursos gratuitos',
        pensados: 'pensados para o seu',
        pessoal: 'desenvolvimento pessoal.',
      },

      HOME: {
        cursosPara: 'Cursos para',
        salutation: 'Olá,',
        title: 'Que bom te ver por aqui!',
        categories: 'Conheça todas as nossas categorias',
        academies: 'Confira abaixo as academias exclusivas Galena',
        academies_subtitle:
          'Nossas academias possuem conteúdos exclusivos para o seu desenvolvimento profissional',
        carousel_categories: 'Confira abaixo o que está incluso no seu plano',
        carousel_categories_subtitle:
          'No seu plano você tem acesso à diversas bolsas e cursos na plataforma Galena sem custo!',
        first_title:
          'Que bom ter você aqui conosco no seu benefício educacional!',
        access: 'Com Galena você tem acesso a',
        partners: '+150 mil',
        experience: 'experiências de aprendizado com até',
        discount: '80% de desconto!',
        exclusive: 'Além de academias exclusivas inclusas no seu benefício ',
        you: 'que você já pode acessar!',
        second_title: ' Pesquisa personalizada',
        plataform:
          ' Na nossa plataforma, você pode buscar por um curso ou instituição através da nossa barra de busca.',
        third_tile: 'Navegue pelas categorias',
        know: 'Ainda não sabe qual curso ou instituição? Você pode navegar através das nossas categorias e encontrar a experiência ideal pra você.',
        fourth_title: 'Com a Galena você vai mais longe',
        power:
          'Acreditamos que a educação tem o poder de transformar a vida das pessoas! E juntos, encontraremos as melhores oportunidades que cabem na sua rotina e no seu bolso.',
        fifth_title: 'Academias exclusivas para você começar a estudar agora!',
        transformation:
          'Que tal começar a transformar sua carreira através da educação agora mesmo?',
        support:
          'Caso tenha alguma dúvida, entre em contato conosco através do  ',
        whatsapp: 'WhatsApp',
        footer: 'no final da página e conte com nosso apoio na sua jornada.',
        education: 'Que falta de educação a nossa,',
        name: ' ainda não sabemos o seu nome! :(',
        called: 'Por favor, insira o seu nome no campo abaixo:',
      },

      REGISTRATION: {
        title: 'Conclua aqui sua inscrição e garanta o desconto!',
        coupon: 'Tem um cupom de desconto?',
        appliedCoupon: 'Cupom aplicado (+',
        appliedCoupon2: '% de desconto)',
        insertCoupon: 'Insira seu cupom aqui',
        invalidCoupon: 'Cupom inválido',
        congratulationTitle: 'Parabéns, você está evoluindo!',
        registrationText:
          'Agora é só aguardar que em breve uma pessoa consultora de carreira irá entrar em contato com você para acordar os próximos passos.',
        satisfactionSurvey:
          'Enquanto isso não acontece, que tal responder nossa pesquisa de satisfação? É muito rápido e ajuda muito!',
        surveyLink: 'Para responder é só clicar aqui.',
        trialUnavailable: 'Inscrição disponível apenas para clientes',

        ESTACIO_TERMS: {
          instructions: 'Para prosseguir, siga as instruções abaixo:',
          confirmTerms: 'Termo de compromisso e condições da bolsa.',
          read_carefully_and_confirm:
            'Leia atentamente e confirme abaixo para prosseguir.',
          deAcordo: 'Li e estou de acordo com as condições da bolsa',
          confirm: 'Confirmar',
          rules: 'Regras e observações',
          oUsuárioCompreende: 'O(A) usuário(a) compreende e concorda que:',
          payment: '1. O pagamento (pré-matrícula) realizado a Galena',
          isento: 'isentará',
          firstPayment:
            'o pagamento da primeira mensalidade da instituição de ensino',
          resources:
            '1.2. As aulas, a matrícula, os prazos de assinatura de documentos, a realização do(s) curso(s) e eventuais emissões de diplomas e certificados serão elaborados e/ou requeridos',
          exclusive: 'EXCLUSIVAMENTE',
          byInstitution:
            'pela instituição de ensino parceira, bem como pelos(as) seus(suas) respectivos(as) professores(as), plataforma (se aplicável), conforme a modalidade escolhida (presencial, semipresencial, EaD, 100% online, Flex).',
          graduationCourses:
            '1.3. Caso o(a) usuário(a) da plataforma da Galena, opte pelos cursos de',
          graduation: 'GRADUAÇÃO',
          highSchoolCertificate:
            ' das Instituições de Ensino Superior (IES) parceiras disponibilizados na plataforma, deverá estar ciente de que as IES exigem o certificado de conclusão do Ensino Médio para o ingresso em cursos de GRADUAÇÃO conforme o inciso II do artigo 44 da Lei 9.394/96. Portanto, podem, eventualmente, classificar, aprovar ou reprovar candidatos com base no desempenho em processos seletivos.',
          infoscholarships: '1.4. Sobre as informações das',
          scholarships: 'BOLSAS DE DESCONTO',
          scholarshipsEdupass: ' disponibilizadas na plataforma da Galena:',
          courseValue:
            'a) Os valores dos cursos e respectivas mensalidades foram informados pela instituição de ensino;',
          valueUpdate:
            'b) A Galena atualiza frequentemente as informações apresentadas sobre os cursos, bolsas de desconto e respectivas instituições de ensino, porém, eventualmente, as informações podem não refletir com exatidão a característica da bolsa de desconto, condições e preços das mensalidades disponíveis no momento da aquisição, podendo sofrer mudanças sem aviso prévio. Nesses casos, prevalecerá as informações da instituição;',
          divergence: 'c) Em caso de divergência entre o valor da',
          bolsa: 'BOLSA',
          restitution:
            'anunciado na plataforma da Galena e o informado pela instituição de ensino no momento da matrícula, o(a) usuário(a) poderá solicitar a restituição do valor pago a título de',
          preMatricula: 'PRÉ-MATRÍCULA',
          reembolso:
            'que seguirá as regras do reembolso quanto ao prazo e forma; tal como cancelar a matrícula na instituição de ensino. Se o(a) usuário(a) não se manifestar após a comunicação de divergência dos valores, será presumida a concordância tácita com os valores informados pela instituição de ensino.',
          universityEducation:
            '1.5. (No caso de escolha de curso(s) em INSTITUIÇÃO DE ENSINO SUPERIOR): a Galena não garante a manutenção da bolsa de desconto no retorno ou decorrer do curso em caso de trancamento de matrícula, abandono de curso, inadimplência ou sanções acadêmicas e/ou disciplinares realizadas pela instituição de ensino parceira.',
          demissao:
            '1.6. (Em casos em que o(a) usuário(a) seja demitido(a) ou se desligue da empresa em que trabalha(ou): a Galena assegura que o(a) usuário(a) siga com a mesma bolsa de desconto adquirida até o término do curso escolhido. Contudo, o(a) usuário(a) perde o benefício de adquirir novas bolsas.',
          alreadyStudent:
            '1.7. O(a) usuário(a) já previamente matriculado(a) na instituição de ensino parceira da Galena,',
          naoPossuiDireito:
            'possui direito à bolsa na mesma instituição de ensino em que tem vínculo.',
          nao: 'NÃO',
          paymentConfirmation:
            '2. Após a Galena sinalizar a confirmação do pagamento por meio',
          deste: 'deste',
          comprovanteMatricula:
            'e comprovante, o(a) usuário(a) será direcionado(a) pela Galena para a instituição de ensino parceira para a',
          completionErollment: 'conclusão da matrícula.',
          preEnrollment: '2.1. A pré-matrícula',
          imposedRequirements:
            'vale como matrícula no curso escolhido, sendo responsabilidade do(a) usuário(a) cumprir os requisitos impostos pela instituição de ensino, incluindo, mas não se limitando à obtenção de notas mínimas em provas de admissão (vestibular e demais processos seletivos), assim como a entrega e assinatura em documentos no prazo estipulado',
          byTheEducationalInstitution: ' pela instituição de ensino.',
          rightToDiscount:
            '2.2. Adquirida a pré-matrícula do curso escolhido, é assegurado o direito ao desconto, desde que respeitadas as características da bolsa de desconto adquirida, especificamente: a instituição de ensino, o curso, o turno, campus/polo/unidade, bimestre e semestre.',
          financialProcess:
            '2.3. Após a realização da matrícula, todo o processo financeiro de cobrança será',
          entreAlunoEInstituicao:
            'entre o(a) aluno(a) e a(s) instituição(ões) de ensino parceira(s).',
          discountException:
            '2.4. O desconto se aplica a todas as matrículas e rematrículas, com exceção das disciplinas nas quais o(a) usuário(a) for reprovado(a).',
          readjustmentIPCA:
            '2.5. Realizada a matrícula na instituição de ensino parceira, a Galena assegura o desconto percentual até o final do curso escolhido, porém valores cobrados pela instituição de ensino estão sujeitos à reajustes conforme IPCA e demais (caso ocorram) acordados entre o(a) estudante e a instituição porcontrato, não sendo responsabilidade da Galena.',
          toObtainExemption:
            '2.6. Para obter a isenção da primeira mensalidade do curso, o(a)',
          benefitedUser: ' USUÁRIO(A) BENEFICIADO(A) NÃO',
          solidaryDilution:
            'deverá realizar o pagamento do boleto de matrícula da DIS (diluição solidária) no valor de R$79,00 emitido pela',
          EstacioDeSa: 'Universidade Estácio de Sá',
          communicateToCampus:
            'e comunicar a(o) campus/polo/unidade da instituição que',
          wantToJoin:
            ' deseja aderir. A isenção será aplicada pela instituição em até 10 dias úteis após a entrega da documentação para matrícula no(a) campus/polo/unidade escolhida.',
          DIS: '2.7. A DIS é a diluição da mensalidade em seu valor bruto, sendo os R$49,00 abatido da mensalidade sem o desconto, acrescentando o valor restante da diluição nas próximas mensalidades. Caso efetue o pagamento da DIS, a isenção da mensalidade e lançamento da bolsa só será realizada após o término da diluição solidária.',
          inCaseOf: '3. Em caso de',
          noClassFormation: ' NÃO FORMAÇÃO DE TURMA',
          devolution:
            'A, o(a) usuário(a) terá direito à devolução/troca da bolsa dentro do prazo de 30 dias corridos após o início das aulas. Para isso, deverá entrar em contato com a Galena dentro do prazo informado e, conforme a apresentação de documento hábil a comprovar a não formação de turma fornecido pela instituição de ensino parceira, indicando os dados pessoais, bancários e informando a bolsa adquirida, objetivando:',
          valueReturn: 'I) a devolução do valor pago (pré-matrícula); ou',
          bagExchange:
            'II) a troca da bolsa por outra disponível na plataforma.',

          caseOfWithdrawal: '4. Informamos que, em caso de',
          cancellationProcess:
            'desistência ou cancelamento do curso ou da matrícula, é necessário que o cursante realize o processo de cancelamento junto a instituição de ensino ',
          reportTheCancellationToEdupass:
            'e comunique a Galena. Caso o cancelamento seja realizado num prazo de até 30 dias após o pagamento da pré-matrícula este poderá ser reembolsado, após esse prazo o reembolso não está disponível. A Galena não é responsável pelo cancelamento da matrícula do cursante junto a instituição sendo assim não se responsabiliza por qualquer ônus proveniente da não realização deste processo.',
          ruleFive:
            '5. Em quaisquer das modalidades de diluição, parcelamento, abatimento, desconto e/ou bolsas, doravante, para os fins desta cláusula, denominados “Benefício” ou “Benefícios”, o pagamento de qualquer uma das parcelas pelo Contratante e/ou seu responsável legal/ financeiro após o prazo de vencimento dará à Instituição de Ensino, a seu exclusivo critério, o direito de cancelar, total ou parcialmente, de maneira definitiva, ou não, o Benefício concedido.',
        },
      },

      NEW_CHECKOUT: {
        FULL_PRICE: {
          CONDITIONS: {
            instructions: 'Para prosseguir, siga as instruções abaixo:',
            alreadySubscribed: 'Você já estuda na',
            yes: 'Sim',
            no: 'Não',
            confirmTerms: 'Termo de compromisso e condições da bolsa.',
            read_carefully_and_confirm:
              'Leia atentamente e confirme abaixo para prosseguir.',
            deAcordo: 'Li e estou de acordo com as condições da bolsa',
            confirm: 'Confirmar',
            ohNo: 'Essa não! :(',
            unavailableMessage:
              'Infelizmente você não pode fazer este curso, pois ele é apenas para novos alunos na instituição',
            close: 'Fechar',
          },
          SUMMARY: {
            summary: 'Resumo do curso',
            modality: 'Modalidade',
            duration: 'Duração',
          },

          REDIRECT: {
            pageTitle: 'Pagamento',
            redirecting:
              'Você está sendo direcionado para a página de pagamento',
          },
        },

        EDUPLAY: {
          CONDITIONS: {
            instructions: 'Para prosseguir, siga as instruções abaixo:',
            confirmTerms: 'Termo de compromisso e condições da bolsa.',
            read_carefully_and_confirm:
              'Leia atentamente e confirme abaixo para prosseguir.',
            deAcordo: 'Li e estou de acordo com as condições da bolsa',
            confirm: 'Confirmar',
          },
          SUMMARY: {
            summary: 'Resumo do curso',
            modality: 'Modalidade',
            duration: 'Duração',
            certificate: 'Certificado',
            discount: 'desconto',
            monthly: 'Valor da parcela em 12x',
            from: 'de',
            to: 'por',
            edupass_economy: 'Economia com Galena',
            total: 'Total a pagar:',
            condition: 'em até 12x sem juros.',
            coupon: 'Tem um cupom de desconto?',
            complete: 'Garantir bolsa agora',
            insertCoupon: 'Insira seu cupom aqui',
            invalidCoupon: 'Cupom inválido',
          },

          REDIRECT: {
            pageTitle: 'Pagamento',
            redirecting:
              'Você está sendo direcionado para a página de pagamento',
          },
        },

        SHARED: {
          COURSE_DETAILS: {
            edupass_economy: 'Economia com Galena',
            discount: 'desconto',
            monthly: 'Valor da parcela em ',
            monthly1: 'x',
            from: 'de',
            to: 'por',
            total: 'Total a pagar:',
            condition: 'em até ',
            condition1: 'x sem juros.',
            coupon: 'Tem um cupom de desconto?',
            complete: 'Garantir bolsa agora',
            trialUnavailable: 'Inscrição disponível apenas para clientes',
          },
        },
      },
    },

    EDUPLAY: {
      CONCLUSAO_CURSO: {
        parabens: 'Parabéns! Você concluiu o curso de',
        certificado:
          'Enviamos o seu certificado no e-mail cadastrado na Galena.',
        baixar: 'Você também pode baixá-lo pelo botão abaixo.',
        btnBaixar: 'BAIXAR CERTIFICADO',
      },

      CURSOS_NAVIGATION: {
        andamento: 'Cursos em andamento',
        finalizados: 'Cursos concluídos',
      },

      CURSOS_PAINEL: {
        aprendizado: 'Meu aprendizado',
        andamento: 'Cursos em andamento',
        concluido: 'Cursos concluídos',
        acessar: 'ACESSAR CURSO',
      },

      EDUPLAY_DETALHES: {
        curso: 'Sobre o curso',
        instituicao: 'Sobre a Instituição',
        btnAcessar: 'ACESSAR CURSO',
        btnAdquira: 'ADQUIRA JÁ',
        btnBreve: 'EM BREVE',
        btnInscrever: 'INSCREVER',
        duration: 'Duração do curso',
        modality: 'Modalidade',
      },

      VIDEO_AULAS: {
        visao: 'Acompanhe as aulas do curso',
        progresso: 'Progresso:',
        btnAnterior: 'Anterior',
        btnProximo: 'Próximo',
        btnVerCertificado: 'Ver certificado',
        acessar: 'ACESSAR CURSO',
        meuAprendizado: 'Meu aprendizado',
        downloadContent: 'Baixar material complementar',
      },
    },

    EMPRESA: {
      COLABORADOR_DETALHES: {
        header: 'Detalhes do Colaborador',
        criado: 'Criado em:',
        ultimoAcesso: 'Último acesso em:',
        btnVoltar: 'VOLTAR',
        btnEditar: 'EDITAR',
        headerCurso: 'Curso',
        headerDataMatricula: 'Data de matrícula',
      },

      COLABORADORES: {
        COLABORADORES_FILTROS: {
          todos: 'Todos',
          ativos: 'Ativos',
          inativos: 'Inativos',
          bloqueados: 'Bloqueados',
          inscricoes: 'Inscrições',
          matriculas: 'Matrículas',
          headerMatricula: 'MATRÍCULAS',
          btnCancelar: 'CANCELAR',
          btnFiltrar: 'FILTRAR',
        },
        COLABORADORES_LISTA: {
          bloquear: 'Bloquear',
          enviar: 'Enviar e-mail de login',
          placeHolderPesquisar: 'Pesquisar por colaborador',
          acoes: 'AÇÕES EM MASSA',
          exportar: 'EXPORTAR',
          modalHeaderBloquear: 'Bloquear acesso do colaborador?',
          certezaBloquear:
            'Tem certeza que deseja bloquear o acesso do colaborador',
          btnCancelar: 'CANCELAR',
          btnBloquear: 'BLOQUEAR',
          modalHeaderResetar: 'Resetar senha do colaborador?',
          certezaResetar:
            'Tem certeza que deseja resetar a senha do colaborador',
          btnResetar: 'RESETAR',
          editar: 'Editar',
          reenviarLogin: 'reenviar login',
          resetarSenha: 'Resetar senha',
          nome: 'Nome',
          email: 'Email',
          admissao: 'Admissão',
          emailLogin: 'Email de login enviado.',
          senhaResetada: 'Senha resetada com sucesso',
        },

        header: 'Cadastro de colaboradores ',
        headerAdm: 'Painel de Administradores',
        navegue: 'Navegue pelos perfis das empresas.',
        placeholderEmpresa: 'Digite o nome da empresa',
        cadastre: 'Cadastre um novo colaborador',
        btnAdicionar: 'ADICIONAR COLABORADOR',
        pesquise: 'Pesquise por um colaborador',
      },

      COLABORADORES_CADASTRO: {
        cadastroColaborador: 'Cadastro de colaborador',
        nome: 'Nome',
        errorMessage: 'Campo obrigatório',
        cidade: 'Cidade',
        senha: 'Senha',
        erroSenha:
          'Senha muito fraca. Sua senha precisa ter no mínimo 8 caracteres, um número e uma letra.',
        admissao: 'Admissao',
        administrador: 'Administrador',
        btnCancelar: 'CANCELAR',
        btnSalvar: 'SALVAR',
      },

      DASHBOARD: {
        pageTitle: 'Dashboard Galena',
        hello: 'Olá',
        welcome: 'seja bem-vindo ao seu dashboard de acompanhamento.',
        totalEconomy: 'Economia Total',
        reais: 'R$',
        totalEligibles: 'Total Cadastrados',
        employee: 'Gestão de colaboradores',
        totalActives: 'Total Ativos',
        totalInscriptions: 'Total Inscrições',
        totalEnrollments: 'Total Matrículas',
        nomeColaborador: 'Colaborador',
        emailColaborador: 'E-mail',
        numeroInscricoes: 'Número de inscrições',
        instituicaoCurso: 'Instituição',
        categoriaCurso: 'Categoria',
        nomeCurso: 'Curso',
        numeroInscriçãoCurso: 'Número de inscrições',
        tituloCurso: 'Top 5 cursos',
      },

      MANAGER_AUTH: {
        MANAGER_AUTH_APPROVED: {
          labelColaboradorIndicado: 'Colaborador indicado como gestor',
          labelEmailColaboradorIndicado:
            'E-mail do colaborador indicado como gestor',
          labelDepartamento: 'Departamento',
          labelColaboradorResponsavel: 'Coloborador responsável pela indicação',
          labelEmailColaboradorResponsavel:
            'E-mail do coloborador responsável pela indicação',
        },
        MANAGER_AUTH_NAVIGATION: {
          pendentes: 'Pendentes',
          aprovados: 'Aprovados',
          reprovados: 'Reprovados',
          banner: 'Análise de Perfil Gestor',
        },
        MANAGER_AUTH_PENDING: {
          labelColaboradorIndicado: 'Colaborador indicado como gestor',
          labelEmailColaboradorIndicado:
            'E-mail do colaborador indicado como gestor',
          labelDepartamento: 'Departamento',
          aprovarHabilitacao: 'Aprovar habilitação de perfil gestor',
          reprovarHabilitacao: 'Reprovar habilitação de perfil gestor',
          labelColaboradorResponsavel: 'Coloborador responsável pela indicação',
          labelEmailColaboradorResponsavel:
            'E-mail do coloborador responsável pela indicação',
        },
        MANAGER_AUTH_REPROVED: {
          labelColaboradorIndicado: 'Colaborador indicado como gestor',
          labelEmailColaboradorIndicado:
            'E-mail do colaborador indicado como gestor',
          labelDepartamento: 'Departamento',
          labelColaboradorResponsavel: 'Coloborador responsável pela indicação',
          labelEmailColaboradorResponsavel:
            'E-mail do coloborador responsável pela indicação',
        },
      },

      REFUND: {
        REFUND_APPROVED: {
          labelDataAprovacao: 'Data da aprovação',
          labelDataSolicitacao: 'Data da solicitação',
          labelColaborador: 'Colaborador',
          labelGestor: 'Gestor',
          labelReembolso: 'Reembolso (%)',
          labelValorReembolso: 'Valor do reembolso',
          labelValorTotalCurso: 'Valor total do curso',
          labelCancelAprovacao: 'Cancelar aprovação',
          labelEmailColaborador: 'E-mail do colaborador',
          labelEmailGestor: 'E-mail do gestor',
          labelFormaPgto: 'Forma de pagamento',
          labelNumeroparcelas: 'Número de parcelas',
          labelCurso: 'Curso',
          labelInstituicao: 'Institução',
          labelMatriculas: 'Matrícula com Galena',
          voceSelecionou:
            'Você selecionou a opção de reprovar uma solicitação de reembolso',
          voceConfirma: 'Você confirma essa operação?',
          btnCancelar: 'Cancelar',
          btnSalvar: 'Confirmar',
          atention: 'Atenção',
        },
        REFUND_CANCEL_REQUEST: {
          labelDataSolicitacao: 'Data da solicitação',
          labelDataAprovacao: 'Data da aprovação',
          labelColaborador: 'Colaborador',
          labelReembolso: 'Reembolso (%)',
          labelValorReembolso: 'Valor do reembolso',
          labelValorTotalCurso: 'Valor total do curso',
          valorUtilizado: 'Valor utilizado',
          aprovarSolicitacao: 'Aprovar solicitação de cancelamento',
          reprovarSolicitacao: 'Reprovar solicitação de cancelamento',
        },
        REFUND_NAVIGATION: {
          pendentes: 'Pendentes',
          aprovados: 'Aprovados',
          cancelled: 'Cancelamentos',
          reprovados: 'Cancelados / Reprovados',
          aprovarComprovante: 'Aprovar Comprovantes',
          comprovantesAprovados: 'Comprovantes Aprovados',
          banner: 'Gestão de Reembolsos',
          reembolso: 'Reembolsos',
          all: 'Todos',
          comprovanteReembolso: 'Comprovantes de Reembolso',
        },
        REFUND_PENDING: {
          labelDataSolicitacao: 'Data solicitação',
          labelColaborador: 'Colaborador',
          labelGestor: 'Gestor',
          labelReembolso: 'Reembolso (%)',
          labelValorReembolso: 'Valor do reembolso',
          labelValorTotalCurso: 'Valor total do curso',
          labelAprovarTodas: 'Aprovar todas',
          labelRecusarTodas: 'Recusar todas',
          emailColaborador: 'E-mail do colaborador',
          emailGestor: 'E-mail do gestor',
          formaPagamento: 'Forma de pagamento',
          numeroParcelas: 'Número de parcelas',
          labelCurso: 'Curso',
          labelInstituicao: 'Institução',
          labelMatriculaEdupass: 'Matrícula com Galena',
          labelJustificativa: 'Justificativa',
          atencao: 'Atenção',
          voceSelecionou:
            'Você confirma que deseja reprovar uma solicitação de reembolso?',
          btnCancelar: 'Cancelar',
          btnSalvar: 'Confirmar',
          operacaoRealizadaSucesso: 'Operação realizada com sucesso',
          importante: 'Importante: você ainda tem',
          solicitacoesPendentes: 'solicitações pendentes',
          verSolicitacoesPendentes: 'Ver solicitações pendentes.',
          voceNaoTem: 'Você não tem',
          verSolicitacoesAprovadas: 'Ver solicitações aprovadas.',
        },
        REFUND_RECEIPT_APPROVAL: {
          labelDataUpload: 'Data de envio do comprovante',
          labelDataSolicitacao: 'Data da solicitação',
          labelColaborador: 'Colaborador',
          labelGestor: 'Gestor',
          labelReembolso: 'Reembolso (%)',
          labelValorReembolso: 'Valor do reembolso',
          labelValorTotalCurso: 'Valor total do curso',
          labelComprovante: 'Comprovante',
          labelAprovarTodas: 'Aprovar todas',
          labelRecusarTodas: 'Recusar todas',
          emailColaborador: 'E-mail do colaborador',
          emailGestor: 'E-mail do gestor',
          formaPagamento: 'Forma de pagamento',
          numeroParcelas: 'Número de parcelas',
          labelCurso: 'Curso',
          labelInstituicao: 'Institução',
          labelMatriculaEdupass: 'Matrícula com Galena',
        },
        REFUND_RECEIPT_APPROVED: {
          labelDataSolicitacao: 'Data da solicitação',
          labelDataUploadComprovante: 'Data de envio do comprovante',
          labelColaborador: 'Colaborador',
          labelReembolso: 'Reembolso (%)',
          labelValorReembolso: 'Valor do reembolso',
          labelValorTotalCurso: 'Valor total do curso',
          labelComprovante: 'Comprovante',
          emailColaborador: 'E-mail do colaborador',
          labelGestor: 'Gestor',
          emailGestor: 'E-mail do gestor',
          formaPagamento: 'Forma de pagamento',
          numeroParcelas: 'Número de parcelas',
          labelCurso: 'Curso',
          labelInstituicao: 'Institução',
          labelMatriculaEdupass: 'Matrícula com Galena',
        },
        REFUND_REPPROVED: {
          labelDataSolicitacao: 'Data da solicitação',
          dataReprovação: 'Data Reprovação',
          labelColaborador: 'Colaborador',
          labelGestor: 'Gestor',
          labelReembolso: 'Reembolso (%)',
          labelValorTotalCurso: 'Valor total do curso',
          labelValorReembolso: 'Valor do reembolso',
          labelvalorUtilizado: 'Valor utilizado',
          emailColaborador: 'E-mail do colaborador',
          emailGestor: 'E-mail do gestor',
          formaPagamento: 'Forma de pagamento',
          numeroParcelas: 'Número de parcelas',
          labelCurso: 'Curso',
          labelInstituicao: 'Institução',
          labelMatriculaEdupass: 'Matrícula com Galena',
        },
      },

      REFUND_RECEIPT: {
        REFUND_RECEIPT_NAVIGATION: {
          banner: 'Gestão de comprovantes',
          pending: 'Pendentes',
          report: 'Todos reembolsos',
        },
      },

      RELATORIOS: {
        COLABORADOR: {
          headerCurso: 'Curso',
          headerCategoria: 'Categoria',
          headerInstituicao: 'Instituição',
          headerDesconto: 'Desconto',
          headerEconomia: 'Economia',
          headerStatus: 'Status',
          header: 'Relatórios',
          vejaAtividades: 'Veja a atividade de',
          naEdupass: 'na Galena',
          placeholderPesquisar: 'Pesquisar colaborador',
          btnExportar: 'EXPORTAR',
        },
        EMPRESA: {
          headerNome: 'Nome',
          headerCursosInscritos: 'Cursos inscritos',
          headerCursosMatriculados: 'Cursos matriculados',
          headerCursosConcluidos: 'Cursos concluídos',
          header: 'Relatórios',
          engajamento: 'Engajamento geral dos colaboradores',
          utilizeFiltros:
            'Utilize os filtros abaixo para visualizar a atividade de todos os colaboradores.',
          btnFiltrar: 'FILTRAR',
          btnExportar: 'EXPORTAR',
        },

        REFUND_REPORT: {
          idSolicitacao: 'ID da Solicitação',
          labelDataSolicitacao: 'Data de solicitação',
          labelColaborador: 'Nome',
          labelEmailColaborador: 'E-mail',
          labelValorTotalReembolso: 'Valor do reembolso',
          labelPercentualReembolso: 'Reembolso (%)',
          status: 'Status',
          labelInstituicao: 'Institução',
          labelCurso: 'Curso',
          labelMatriculaEdupass: 'Matrícula com Galena',
          labelRefundName: 'Nome pagamento',
          labelCpf: 'CPF',
          labelPaymentMethod: 'Método de pagamento',
          labelPix: 'Chave Pix',
          bankNum: 'Número Banco',
          bankName: 'Banco',
          bankAgency: 'Agência',
          bankAccount: 'Conta Corrente',
          vejaReembolso: 'Veja os reembolsos de todos os funcionários',
          labelBusqueColaborador: 'Busque por um colaborador',
          labelStatus: 'Busque por um status',
          labelPeriodo: 'Busque por um período',
          btnClearFilters: 'Limpar Seleção',
          btnExportar: 'Exportar Seleção',
        },

        REFUND_REPORT_COLLABORATOR: {
          labelColaborador: 'Colaborador',
          labelReembolso: 'Reembolso (%)',
          labelValorTotalReembolso: 'Valor total do reembolso',
          labelValorTotalCurso: 'Valor total do curso',
          labelComprovante: 'Comprovante',
          labelEmailColaborador: 'E-mail do colaborador',
          labelGestor: 'Gestor',
          labelEmailGestor: 'Email do Gestor',
          labelFormaPgto: 'Forma de pagamento',
          labelNumeroParcelas: 'Número de parcelas',
          labelCurso: 'Curso',
          labelInstituicao: 'Institução',
          labelMatriculaEdupass: 'Matrícula com Galena',

          banner: 'Relatórios',
          seeReceipts:
            'Veja os comprovantes de reembolsos de todos os funcionários',
          searchPeriod: 'Busque por um período',
          searchStatus: 'Busque por um status',
          searchEmployee: 'Busque por um colaborador',
          btnExport: 'EXPORTAR',
        },

        LMS: {
          header: 'Relatório Universidade Corporativa',
          labelStatus: 'Filtrar por:',
          labelPeriodo: 'Filtre por uma data de conclusão',
          labelBusqueColaborador: 'Fitre pelo nome do colaborador',
          labelBusqueCurso: 'Filtre pelo nome do curso',
          btnExportar: 'Exportar tudo',
          btnLimpar: 'Limpar filtros',
          btnBuscar: 'Buscar',
          btnExportarSelection: 'Exportar seleção',
        },

        ENGLISH_PASS: {
          header: 'Relatórios',
        },

        REPORTS_NAVIGATION: {
          engajamentoGeral: 'Engajamento Geral',
          engajamentoColaboradores: 'Engajamento por Colaborador',
          lms: 'Relatório Universidade Corporativa',
          english_pass: 'English Pass',
        },
      },
    },

    INSTITUTIONS: {
      INSTITUTIONS_NAVIGATION: {
        banner: 'Instituições parceiras Galena',
        graduation: 'Graduação',
        post_graduation: 'Pós-Graduação',
        languages: 'Idiomas',
        tech_digital: 'Tecnologia & Digital',
        personal_development: 'Desenvolvimento Pessoal',
      },
    },

    LOGIN: {
      FORGOT_PASSWORD: {
        success: 'Email de redefinição de senha enviado!',
        error: 'Usuário não encontrado',
        btnVoltar: 'Voltar',
        esqueceuSenha: 'Esqueceu a sua senha?',
        erroMessageEmailCpf: 'Informe um e-mail ou CPF válidos',
        labelEmailCpf: 'Informe o seu email ou CPF',
        erroMessageEmail: 'Informe um e-mail válido',
        labelEmail: 'Informe o seu e-mail',
        btnEnviar: 'ENVIAR',
      },

      REGISTER_FORM: {
        cpfError: 'Este CPF não confere com o registrado para este email',
        erroMessageCpf: 'Você precisa informar um CPF válido',
        errorMessage: 'Campo obrigatório',
        emailUtilizado: 'Este email já foi utilizado',
        btnVoltar: 'Voltar',
        crieConta: 'Crie a sua conta.',
        camposObrigatorios: 'Todos os campos são obrigatórios.',
        emailInformado: 'E-mail informado:',
        labelEmail: 'Informe o seu e-mail',
        alterarEmail: 'Alterar o e-mail',
        labelNome: 'Nome completo',
        placeholderNome: 'Digite o seu nome completo',
        labelEmpresa: 'Empresa',
        placeholderEmpresa: 'Digite o nome da sua empresa',
        placeholderCelular: 'Digite o seu celular',
        labelCelular: 'Celular',
        exemplo: 'Exemplo: (11)9999-9999',
        erroMessageCelular: 'Você precisa informar um telefone válido',
        importante: 'Importante',
        acessoEdupassBloqueado:
          'O seu acesso a Galena pode ser bloqueado caso a empresa na qual você trabalha invalide o e-mail informado',
        labelCpf: 'Digite o seu CPF',
        porqueCpf: 'Por que precisamos do seu CPF?',
        cpfUsado:
          'O CPF é usado para confirmar o seu vínculo com a empresa informada.',
        aceitarTermos: 'Você deve aceitar os termos para poder se registrar',
        concordo: 'Li e concordo com os',
        termos: 'Termos de uso',
        eCom: 'e com a',
        politica: 'Política de Privacidade',
        btnEnviar: 'ENVIAR',
        enviamos: 'Enviamos um link de definição de senha para o e-mail:',
        verifique: 'Verifique o seu e-email!',
        spam: 'Fique atento a caixa de SPAM.',
        acessoBloqueado:
          'O seu acesso poderá ser bloqueado, caso a sua empresa invalide o e-mail informado.',
        cpfAssociado: 'Este CPF está associado ao email:',
        acesseEdupass: 'Acesse a Galena usando o e-mail',
        eSenha: 'e senha',
        irParaLogin: 'Ir para o Login',
        naoSabeSenha: 'Não sabe a sua senha?',
        redefinaSenha: 'Redefina a sua senha',
      },

      REGISTRAR: {
        senhaFraca:
          'A senha é muito fraca (mínimo 8 caracteres, 1 número e 1 letra)',
        erroSenha: 'Você precisa informar uma senha válida',
        erroDefinirSenha: 'Ocorreu um erro na definição de senha',
        ola: 'Olá,',
        crieSenha: 'Crie uma senha',
        labelDigiteSenha: 'Digite uma senha',
        labelDigiteSenhaNovamente: 'Digite a senha novamente',
        erroCoincidir: 'As senhas devem coincidir',
        btnSalvar: 'SALVAR',
        direcionadoEdupass: 'Você está sendo redirecionado para a Galena.',
        direcionadoAutomatico:
          'Caso não seja redirecionado automaticamente, clique aqui.',
      },

      TROCASENHA: {
        senhaFraca:
          'Senha muito fraca. Sua senha precisa ter no mínimo 8 caracteres, um número e uma letra.',
        senhaValida: 'Você precisa informar uma senha válida',
        senhaInformada: 'A senha deve ser igual a informada no campo anterior',
        mesmaSenha: 'Você precisa informar a mesma senha do campo acima',
        definaSenha: 'Defina sua nova senha',
        labelSenha: 'Senha',
        confirmaSenha: 'Confirme sua senha',
        btnSalvar: 'SALVAR',
      },

      welcome: 'Bem-vindo/a ao seu benefício em educação!',
      firstLine:
        'Aqui você encontra centenas de instituições de ensino com abrangência nacional,',
      secondLine: 'são  ',
      emphasis: '+150mil cursos',
      secondLine_2: 'de graduação, idiomas, pós-graduação e muito mais!',
      thirdLine: 'Tudo isso com ',
      emphasis_2: 'descontos exclusivos que podem chegar até 80%.',
      temConta: 'Fazer login',
      facaLogin: 'Já tem uma conta? Faça o seu login abaixo.',
      emailOuCpf: 'E-mail ou CPF',
      senha: 'Senha',
      btnEntrar: 'Entrar',
      esqueceuSenha: 'Esqueci a minha senha',
      dificuldade: 'Está com dificuldade para acessar?',
      admitidiRecentemente:
        'Se você foi admitido recentemente, pode demorar alguns dias até que o seu acesso seja liberado.',
      cadastrouEmail:
        'Se você cadastrou o seu e-mail pessoal, a sua empresa pode ter atualizado o seu cadastro para o e-mail corporativo.',
      beneficioEmpresa:
        ' Caso sua empresa ofereça o benefício Galena e ainda não tenha criado a sua conta, informe seu e-mail corporativo para cadastra-lo na plataforma (:',
      informarEmail: 'Você precisa informar um email',
      btnCadastrar: 'Cadastrar agora',
      redefinirSenha: 'Gostaria de redefinir a sua senha?',
      clickBtn: 'Clique no botão abaixo.',
      btnRedefinirSenha: 'Redefinir senha',

      informarEmailInvalido: 'Você precisa informar um email válido',
      emailJaCadastrado: 'Email já cadastrado',
      informarEmailSenha: 'É preciso informar o usuário e senha',
      emailCpfValido: 'Insira um email ou cpf válido',
      erroLogin: 'Usuário ou senha inválidos',
      primeiroAcesso: 'É o seu primeiro acesso?',
      comeceAqui: 'Comece por aqui.',
      informeEmail: 'Informe seu e-mail corporativo',

      naoCadastrado: 'não está cadastrado',
      gostariaCadastrar: 'Gostaria de se cadastrar?',

      welcomeLeroy: 'Bem-vindo(a) a Galena, seu benefício de educação.',
      timeLeroy: 'Chegou sua hora de estudar com descontos exclusivos!',
      leroyUser: 'Olá colaborador(a) Leroy Merlin, para acessar ao benefício',
      leroyUser2: 'Galena, clique no botão abaixo.',
      leroyAuth: 'Autenticação PingOne Leroy',
    },

    DADOS: {
      CATEGORIAS: {
        graduacao: 'Graduação',
        pos_graduacao: 'Pós Graduação',
        idiomas: 'Idiomas',
        tecnologia: 'Tech & Digital',
        desenvolvimento_pessoal: 'Desenvolvimento Pessoal',
        lideranca: 'Pré-Vestibular',
        hobbies: 'Ensino Básico',
        eduplay: 'Eduplay',
        'meus-cursos': 'Recomendações para o meu perfil',
        kids: 'Para crianças e adolescentes',
        tecnicos: 'Técnicos',
        curta_duracao: 'Curta Duração',
        lideranca_gestao: 'Gestão',
      },
    },

    COMPONENTS: {
      PESQUISA: {
        outro: 'Outro',
      },
    },

    SHARED: {
      CARD_CURSO_ANDAMENTO: {
        progress: 'Progresso',
        continuarCurso: 'CONTINUAR CURSO',
        nenhumCurso: 'Você não tem nenhum curso em andamento',
        explore: 'Explore nosso catálogo de cursos gratuitos Eduplay',
        catalogo: 'Ir para catálogo',
      },
      CARD_CURSOS_CONCLUIDOS: {
        accessCertificate: 'ACESSAR CERTIFICADO',
        courseDetails: 'Informações do curso',
        nenhumCursoConcluido: 'Você não tem nenhum curso concluído',
        explore: 'Explore nosso catálogo de cursos gratuitos',
        catalogo: 'Ir para catálogo',
      },
      CURSO_CARD: {
        contato: 'Entre em contato conosco para saber o valor do curso',
        valorEdupass: 'Valor com Galena',
        era: 'era',
        bolsa: 'Bolsa',
        botaoSaibaMais: 'SAIBA MAIS',
        botaoInscrever: 'GARANTIR BOLSA',
        precoSobConsulta: '*Preço sob consulta',
        ultimaAtualizacao: 'última atualização',
        leroyMerlin: '**Galena + Leroy Merlin',
        leroyDiscount: '(+30%)',
        leroyValue: 'Valor Galena + Leroy Merlin',
        leroyAttention:
          'o desconto é válido apenas para colaboradores ativos Leroy Merlin.',
        attention: '**Atenção',
        leroyTooltip:
          'A porcentagem de desconto não é acumulativa, sendo assim, a porcentagem de desconto da Leroy Merlin (30%) é aplicada em cima do valor do final em reais do desconto Galena',
      },

      CURSOS_DUVIDAS: {
        pergunta: 'Ainda está com dúvidas?',
        resposta:
          'Clique no botão abaixo e envie as informações solicitadas para que o nosso time de consultores de carreira possa tirar suas dúvidas (:',
      },
      CURSOS_PESQUISA_INPUT: {
        search: 'Pesquise por um curso ou instituição',
      },

      TABLE_DEFAULT: {
        nenhumItem: 'Nenhum item encontrado',
        btnSalvar: 'Salvar alterações',
      },
    },

    LAYOUT: {
      EDUPLAY_MODAL: {
        acesso: 'You have already accessed the courses',
        gratuito: 'FREE',
        eduplay: 'from Eduplay?',
        cursos: 'More than 60 free courses',
        temas1: 'Topics such as Leadership, Communication,',
        temas2: 'Productivity, Excel and others',
        certificado: 'courses with certificate',
        login: 'Login: your corporate email',
        acesse: 'Access here',
      },

      FOOTER: {
        help: 'Precisa de ajuda?',
        call: 'Chama no WhatsApp',
        feedback: 'Feedback?',
        shareOpinion: 'Compartilhe a sua opinião',
        terms: 'Termos de Uso',
        policy: 'Política de Privacidade',
        prefix_rights: 'Galena',
        sufix_rights: '| Todos os direitos reservados',
        version: 'Versão',
      },

      APPBAR: {
        analiseComprovantes: 'Gestão de Comprovantes',
        logout: 'Sair',
        open: 'Ver mais',
        close: 'Ver menos',
        minhaConta: 'Minha Conta',
        labelInicio: 'Início',
        labelCategorias: 'Cursos',
        labelAprendizado: 'Meu aprendizado',
        labelInstitutions: 'Instituições parceiras',
        labelOrders: 'Minhas pré-matrículas',
        labelSenha: 'Alterar senha',
        bestFriday: 'Seleção Best November',
        dependents: {
          add: 'Cadastrar dependentes',
          manage: 'Meus dependentes',
        },
        employees: {
          add: 'Cadastrar Colaborador',
          manage: 'Gestão de colaboradores',
        },
        refundManagement: {
          label: 'Gestão de reembolsos',
        },
        solicitacoes: {
          label: 'Meus reembolsos',
          submenus: {
            acompanharSolicitacao: 'Reembolsos',
          },
        },
        plan_included: 'Inclusos no seu plano',
        categorias: {
          graduacao: 'Graduação',
          pos_graduacao: 'Pós Graduação',
          idiomas: 'Idiomas',
          tecnologia: 'Tech & Digital',
          desenvolvimento_pessoal: 'Desenvolvimento Pessoal',
          lideranca: 'Pré-Vestibular',
          hobbies: 'Ensino Básico',
          eduplay: 'Eduplay',
          kids: 'Para crianças e adolescentes',
          curta_duracao: 'Curta Duração',
          tecnicos: 'Cursos Técnicos',
          lideranca_gestao: 'Gestão',
          escolas_internacionais: 'Idiomas',
        },
        academias: {
          gestao: 'Gestão de Liderança',
          tecnologia: 'Tecnologia',
          diversidade: 'Diversidade',
          vendas: 'Vendas',
          humanos: 'Recursos Humanos',
        },
        labelGestaoEdupass: 'GESTÃO GALENA',
        labelRelatorios: 'Relatórios',
        labelDashboard: 'Dashboard',
        labelCadastroColaborador: 'Cadastro de colaboradores',
        solicitacaoReembolso: 'Gestão de reembolsos',
        analisePerfil: 'Análise de perfil gestor',
        seeAllCategories: 'Ver todas',
        subsidies: {
          mySubsidies: 'Meus reembolsos',
        },
      },

      TOPBAR: {
        salutation: 'Olá,',
        whatsapp: 'WhatsApp',
        email: 'E-mail',
        email_contact: 'Envie um e-mail para: contato@edupass.io',
        call: 'Clique aqui para enviar uma mensagem pelo WhatsApp',
        subtitle: 'Fale com a gente pelos nossos canais',
      },
    },

    PAYMENT_TYPES: {
      MONTHLY: 'Mensalidade',
      INSTALLMENTS: 'Parcelas',
      FULL_PRICE: 'Total',
    },

    REFUND_STATUS: {
      labelTodos: 'Todos',
      labelPendente: 'Curso em Aprovação',
      labelAprovado: 'Curso Aprovado',
      labelRefundPending: 'Reembolso em Aprovação',
      labelRefundApproved: 'Reembolso Aprovado',
      labelCourseReproved: 'Curso Reprovado',
      labelRefundReproved: 'Reembolso Reprovado',
      labelCancelado: 'Cancelado',
    },
  },
};
