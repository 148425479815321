import React from 'react';

import { State, StateActions } from './interfaces';

export const Context = React.createContext<[State, StateActions | null]>(null);

export function WizardProvider({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: State;
}) {
  React.useEffect(() => {
    dispatch({
      type: 'INIT',
      payload: initialState,
    });
  }, [JSON.stringify(initialState)]);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const defaultActions: StateActions = {
    navigate: (nextStepId) => {
      dispatch({
        type: 'NAVIGATE',
        payload: nextStepId,
      });
    },
    updateValues: (values) => {
      dispatch({
        type: 'UPDATE_VALUES',
        payload: values,
      });
    },
  };

  const actions = initialState.actions ?? defaultActions;

  const value: [State, StateActions] = [state, actions];

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function reducer(state: State, action): State {
  switch (action.type) {
    case 'NAVIGATE': {
      return {
        ...state,
        currentStepId: action.payload,
      };
    }
    case 'INIT': {
      return { ...action.payload };
    }
    case 'UPDATE_VALUES': {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    }
  }
}
