export enum PROFILE_CONTEXTS {
  GOAL = 'goal',
  SCHOLARITY = 'scholarity',
  CURRENT_JOB = 'current_job',
  DESIRED_JOB = 'desired_job',
  SENIORITY = 'seniority',
  COURSE_TYPES = 'course_types',
  KNOWLEDGE_AREAS = 'knowledge_areas',
  LANGUAGES = 'languages',
  ADDRESS = 'address',
  MODALITIES = 'modalities',
  MONTHLY_BUDGET = 'monthly_budget',
  WEEKLY_AVAILABILITY = 'weekly_availability',
}
