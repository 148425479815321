import { formatDate } from '@/react/utils/date';
import { FormValues, PurchaseReceipts, PaymentReceipts } from '../interfaces';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';

export const formatFormValues = async (
  resourceApproval: Partial<ResourceApproval>,
  formValues: Partial<FormValues>,
  defaultValues: FormValues
): Promise<FormValues> => {

  const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: contentType });
  };


  const generateFilesFromJson = (receipts = []) => {
    return receipts.flatMap((receipt) =>
      receipt.attachments.map((attachment) => {
        const { filename, blob, content_type } = attachment.content;
        const blobContent = base64ToBlob(blob, content_type);
        return new File([blobContent], filename, { type: content_type });
      })
    );
  };

  const purchaseReceipts =
    resourceApproval?.refund_request?.receipts.filter((receipt) =>
      Object.keys(PurchaseReceipts).includes(receipt.type)
    ) || [];

  const paymentReceipts =
    resourceApproval?.refund_request?.receipts.filter((receipt) =>
      Object.keys(PaymentReceipts).includes(receipt.type)
    ) || [];

  const purchaseAttachments = generateFilesFromJson(purchaseReceipts);
  const paymentAttachments = generateFilesFromJson(paymentReceipts);

  const resourceFiles = (files) => {
    return files.map((file) => {
      const restoredFile = new File([file], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      Object.defineProperty(restoredFile, 'size', { value: file.size });
      return restoredFile;
    });
  };

  const formattedValues = {
      ...defaultValues,
      ...formValues,
      purchaseAttachments:
      formValues.purchaseAttachments?.length > 0
        ? resourceFiles(formValues.purchaseAttachments)
        : purchaseAttachments,

      paymentAttachments:
        formValues.paymentAttachments?.length > 0
          ? resourceFiles(formValues.paymentAttachments)
          : paymentAttachments,

      optionalFiles:
        formValues.optionalFiles?.length > 0
          ? resourceFiles(formValues.optionalFiles)
          : [],

      supplierDocument:
        formValues.supplierDocument ||
        resourceApproval?.refund_request?.supplier_document ||
        '',

      supplierName: formValues.supplierName || resourceApproval?.refund_request?.supplier_name || '',

      documentEmployeer:
        formValues.documentEmployeer ||
        resourceApproval?.refund_request?.receiver_fiscal_document ||
        '',

      supplierType: formValues.supplierType ||
        resourceApproval?.refund_request?.supplier_document?.length > 11
        ? 'legal_person'
        : resourceApproval?.refund_request?.supplier_document
          ? 'natural_person'
          : 'legal_person',

      purchaseDate:
        formValues.purchaseDate ||
        (resourceApproval?.refund_request?.purchase_date
          ? formatDate(resourceApproval?.refund_request?.purchase_date)
          : ''),

      paymentDate:
        formValues.paymentDate ||
        (resourceApproval?.refund_request?.payment_date
          ? formatDate(resourceApproval?.refund_request?.payment_date)
          : ''),

      paymentAmount:
        formValues.paymentAmount ||
        resourceApproval?.refund_request?.payment_amount ||
        '',

      purchaseAmount:
        formValues.purchaseAmount ||
        resourceApproval?.refund_request?.purchase_amount ||
        '',

      referenceMonth:
        formValues.referenceMonth ||
        resourceApproval?.refund_request?.reference_month?.toString() ||
        '',

      typeOfReceiptPurchase:
        formValues.typeOfReceiptPurchase ||
        (purchaseReceipts.length > 0 ? 'nfe' : ''),

      typeOfReceiptPayment:
        formValues.typeOfReceiptPayment ||
        (paymentReceipts.length > 0 ? 'billing_statement' : ''),

        typeOfBankTransfer: formValues.typeOfBankTransfer ||
        (resourceApproval?.refund_request?.banking_info?.bank ? 'ted' : 'pix'),

      holderName:
        formValues.holderName ||
        resourceApproval?.refund_request?.banking_info?.holder_name ||
        '',

      documentEmployeerText: formValues.documentEmployeer ||
      resourceApproval?.refund_request?.receiver_fiscal_document  || '',

      accountNumber:
        formValues.accountNumber ||
        resourceApproval?.refund_request?.banking_info?.account_number ||
        '',

      agency:
        formValues.agency ||
        resourceApproval?.refund_request?.banking_info?.agency ||
        '',

      bank:
        formValues.bank ||
        resourceApproval?.refund_request?.banking_info?.bank ||
        '',

      pixKey:
        formValues.pixKey ||
        resourceApproval?.refund_request?.banking_info?.pix_key ||
        '',

      pixType:
        formValues.pixType ||
        resourceApproval?.refund_request?.banking_info?.pix_type ||
        '',
    };

  return formattedValues;
};
