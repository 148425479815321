import {
  createProfileQuizQuestions,
  QuestionId,
  QuestionNames,
  QUESTIONS_ENUM,
} from './profile-quiz.questions';
import {
  createQuizState,
  QuizState,
  QuizStateValue,
} from '@/react/components/quiz';

import { ProfileQuizInterfaces } from './profile-quiz.interfaces';
import { fromDraft } from '@/react/components/quiz/utils/fromDraft';
import { entries, keyBy } from 'powership';
import { FLAG_ACCEPT_EMAIL_MKT } from '@/react/components/quiz/components/question-type-email';
import { QuestionJobSelectionNode } from '@/react/components/quiz/quiz.interfaces';
import { RecommendationClient } from '@/react/data/recommendation/recommendation-client';
import { ResultUseBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { GOALS } from '@/react/data/recommendation/constants';

// props attached to the quiz state in the extraData field.
type ExtraProfileQuizData = ResultUseBootstrapData;

export type ProfileQuizStateInstance = QuizState<ExtraProfileQuizData>;
export type ProfileQuizStateValue = QuizStateValue<ExtraProfileQuizData>;

export function createProfileQuizState(
  appBootstrapData: ResultUseBootstrapData
): ProfileQuizStateInstance {
  const { isNewcomer, profile: initialProfile } = appBootstrapData;

  const activeNodeId = (() => {
    if (initialProfile?.current_step) return initialProfile.current_step;
    return isNewcomer
      ? QuestionNames.presentation_welcome
      : QuestionNames.presentation_update_preferences;
  })();

  const extraData: ExtraProfileQuizData = appBootstrapData;

  const nodes = createProfileQuizQuestions(extraData);

  const state = createQuizState<ExtraProfileQuizData>({
    defaultActiveNodeId: isNewcomer
      ? QuestionNames.presentation_welcome
      : QuestionNames.presentation_update_preferences,
    activeNodeId,
    nodes,
    extraData,
  });

  state.addMiddleware((change) => {
    const { activeNodeId, hasRequiredNodesEmpty } = change.draft;

    //
    const changedQuestion = activeNodeId !== change.previous.activeNodeId;
    if (!changedQuestion) return;

    const current: any = fromDraft(change.draft);

    const profile = stateToProfile(current);
    change.draft.profile = profile;

    async function patchProfile() {
      await RecommendationClient.patchProfile({
        ...profile,
        id: initialProfile.id,
      });
    }

    async function finishProfile() {
      await RecommendationClient.saveAsComplete({
        profile,
        appBootstrapData,
      });
    }

    if (activeNodeId) {
      patchProfile().catch(console.error);
    } else if (!hasRequiredNodesEmpty) {
      finishProfile().catch(console.error);
    }
  });

  return state;
}

function stateToProfile(
  state: QuizStateValue<ExtraProfileQuizData>
): ProfileQuizInterfaces['partial'] {
  //

  const {
    extraData: { taxonomy },
  } = state;

  const valueByField = QUESTIONS_ENUM.values.reduce((acc, field) => {
    const node = state.nodeById[field];
    if (!node || !node?.touched) {
      // The node can be undefined because the node `condition`
      // may hide it according to the current global state
      return {
        ...acc,
        [field]: [],
      };
    }

    return {
      ...acc,
      [field]: node.value, //
    };
  }, {} as { [F in QuestionId]: string[] });

  const [min_budget_per_month, max_budget_per_month] = valueByField.select_monthly_budget[0]
    ?.split('-')
    .map(value => (value === 'null' ? null : value)) || [];

  valueByField.select_monthly_budget[0] !== undefined && valueByField.select_monthly_budget[0] !== ''
      ? (valueByField.select_monthly_budget[0] !== null ? valueByField.select_monthly_budget[0] : '')
      : null

  const [min_hours_available_per_week, max_hours_available_per_week] =
    valueByField.time_available_per_week[0]?.split('-') || [];

  const goal = valueByField.goals[0];

  const payload = {
    email: valueByField.input_contact_email[0],
    agreed_to_receive_emails:
      valueByField.input_contact_email[1] === FLAG_ACCEPT_EMAIL_MKT,
    current_step: state.activeNodeId,
    address_state: valueByField.location_info[0],
    address_city: valueByField.location_info[1],
    scholarity: valueByField.scholarity[0],
    min_budget_per_month: min_budget_per_month !== undefined && min_budget_per_month !== ''
      ? (min_budget_per_month !== null ? min_budget_per_month : '')
      : null,
    max_budget_per_month: max_budget_per_month !== undefined && max_budget_per_month !== ''
      ? (max_budget_per_month !== null ? max_budget_per_month : '')
      : null,
    min_hours_available_per_week: min_hours_available_per_week
      ? +min_hours_available_per_week
      : null,
    max_hours_available_per_week: max_hours_available_per_week
      ? +max_hours_available_per_week
      : null,
    course_types: valueByField.select_course_interests,
    goal: goal,
    languages: valueByField.select_interesting_languages,
    modalities: valueByField.select_best_learning_modality,
    seniority: valueByField.seniority[0],

    knowledge_areas: (() => {
      const byId = keyBy(taxonomy.knowledge_areas, (el) => el.id);
      return valueByField.select_most_interesting_themes
        .map((el) => byId[+el])
        .filter(Boolean);
    })(),

    desired_job: (() => {
      if (goal !== GOALS.CARRER_CHANGE) return {};

      const node = state.nodeById[
        QuestionNames.desired_job
      ] as QuestionJobSelectionNode;

      return node?.meta?.jobLoadedFromSearch?.id
        ? node?.meta?.jobLoadedFromSearch
        : node?.meta?.jobReloadedFromProfile;
    })(),

    current_job: (() => {
      const node = state.nodeById[
        QuestionNames.current_job
      ] as QuestionJobSelectionNode;

      return node?.meta?.jobLoadedFromSearch?.id
        ? node?.meta?.jobLoadedFromSearch
        : node?.meta?.jobReloadedFromProfile;
    })(),
  };

  return entries(payload).reduce((acc, [k, v]) => {
    if (v === undefined || v === null) return acc;

    const isEmptyArray = Array.isArray(v) && !v.length;

    if (isEmptyArray) {
      return acc;
    }

    return {
      ...acc,
      [k]: v,
    };
  }, {});
}
