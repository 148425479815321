import * as React from 'react';
import Container from '@mui/material/Container';
import { useQuery } from '@tanstack/react-query';
import { constants } from '@/react/constants';

import { CursosSection } from './section.component';
import CourseTypeList from './course-type-list.component';
import CourseTypeListLoading from './course-type-list-loading.component';
import KnowledgeAreaList from './knowledge-area-list.component';
import KnowledgeAreaListLoading from './knowledge-area-list-loading.component';
import { Carousel, CarouselSlide } from '@/react/components/carousel';

import ContentContainer from '@/react/components/layout/content-container.component';
import { restClient } from '@/react/utils/fetch';
import CONST from '@/constants';
import { User } from '@/models/user.model';
import { BootstrapData, CourseType } from './home.interfaces';
import { HomeRecommendations } from './home-recommendations';
import { GtmContext } from '../state/context';

// FIXME: This should consider production/staging/development envs
const baseUrl = 'https://assets.galena.com/edupass-api/public/home-banners';

const slides: CarouselSlide[] = [
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_2',
      creative_name: 'banner-exclusivo-ebac-20.01.25-1',
      creative_slot: 'banner-exclusivo-ebac-20.01.25-1',
      promotion_id: 'banner-exclusivo-ebac-20.01.25-1',
      promotion_name: 'banner-exclusivo-ebac-20.01.25-1',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/banner_1.1_20250120_P.png`,
    link: '/cursos/novo_catalogo?institutions=282&page=1',
    md: {
      src: `${baseUrl}/banners/banner_1.1_20250120_M.png`,
    },
    lg: {
      src: `${baseUrl}/banners/banner_1.1_20250120_G.png`,
    },
    title: `Banner promocional dizendo: 'Cupom Exclusivo EBAC'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_2',
      creative_name: 'cupom-curso-de-ingles-20.01.25-2',
      creative_slot: 'cupom-curso-de-ingles-20.01.25-2',
      promotion_id: 'cupom-curso-de-ingles-20.01.25-2',
      promotion_name: 'cupom-curso-de-ingles-20.01.25-2',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/banner_2.1_20250120_P.pngc`,
    link: '/cursos/180084?location=BR,Nacional&hub=50237&period=flex',
    md: {
      src: `${baseUrl}/banners/banner_2.1_20250120_M.png`,
    },
    lg: {
      src: `${baseUrl}/banners/banner_2.1_20250120_G.png`,
    },
    title: `Banner promocional dizendo: 'Cupom Curso de Inglês'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_3',
      creative_name: 'trilha-competencias-futuro-13.01.24-1',
      creative_slot: 'trilha-competencias-futuro-13.01.24-1',
      promotion_id: 'trilha-competencias-futuro-13.01.24-1',
      promotion_name: 'trilha-competencias-futuro-13.01.24-1',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/Banner-1-P_20250113.png`,
    link: 'https://promo.galena.com/trilha-competencias-do-futuro',
    md: {
      src: `${baseUrl}/banners/Banner-1-M_20250113.png`,
    },
    lg: {
      src: `${baseUrl}/banners/Banner-1-G_20250113.png`,
    },
    title: `Banner promocional dizendo: 'Banner sobre Trilha Competências do Futuro'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_4',
      creative_name: 'volta-as-aulas-pos-graduacao-13.01.25-2',
      creative_slot: 'volta-as-aulas-pos-graduacao-13.01.25-2',
      promotion_id: 'volta-as-aulas-pos-graduacao-13.01.25-2',
      promotion_name: 'volta-as-aulas-pos-graduacao-13.01.25-2',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/Banner-2-P_20250113.png`,
    link: '/cursos/novo_catalogo?course_types=postgraduate',
    target: '_blank',
    md: {
      src: `${baseUrl}/banners/Banner-2-M_20250113.png`,
    },
    lg: {
      src: `${baseUrl}/banners/Banner-2-G_20250113.png`,
    },
    title: `Banner promocional dizendo: 'Banner sobre Pós graduação'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_5',
      creative_name: 'volta-as-aulas-graduacao-13.01.25-3',
      creative_slot: 'volta-as-aulas-graduacao-13.01.25-3',
      promotion_id: 'volta-as-aulas-graduacao-13.01.25-3',
      promotion_name: 'volta-as-aulas-graduacao-13.01.25-3',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/Banner-3-P_20250113.png`,
    link: '/cursos/novo_catalogo?course_types=graduation&page=1',
    target: '_blank',
    md: {
      src: `${baseUrl}/banners/Banner-3-M_20250113.png`,
    },
    lg: {
      src: `${baseUrl}/banners/Banner-3-G_20250113.png`,
    },
    title: `Banner promocional dizendo: 'Banner sobre Graduação'`,
  },
];

const CursosHome = (props: { user: User | null }) => {
  const { user } = props;

  const { isPending: isBootstrapDataPending, data: bootstrapData } =
    useQuery<BootstrapData>({
      queryKey: ['bootstrapData'],
      queryFn: () =>
        restClient.get('/users/bootstrap-data').then((res) => res.data.data),
    });

  const { info, plan, taxonomy } = bootstrapData || {};
  const courseTypes = taxonomy?.course_types || [];
  const knowledgeAreas = taxonomy?.knowledge_areas || [];

  const landingPageFeature = plan?.features.filter(
    (item) => item.name === 'unibrad_pos'
  );

  if (landingPageFeature && landingPageFeature.length > 0) {
    slides.unshift({
      backgroundColor: '#CC092F',
      tagsGTM: {
        id: 'Novidade',
        creative_name: 'Summer Banner',
        creative_slot: 'featured_app_1',
        promotion_id: 'P_12345',
        promotion_name: 'Summer Sale',
      },
      objectFit: 'cover',
      src: `${baseUrl}/unibrad_banners/banner1_mobile.png`,
      link: `${CONST.FRONTEND_ENDPOINT}/cursos/novo_catalogo?unibrad_included=true`,
      md: {
        src: `${baseUrl}/unibrad_banners/banner1_md.png`,
      },
      lg: {
        src: `${baseUrl}/unibrad_banners/banner1_lg.png`,
      },
      title: `Unibrad - Pós-graduação por R$32,50.`,
    });
  }

  const subsidiesFeature = plan?.features.filter(
    (item) => item.name === 'subsidies'
  );

  if (subsidiesFeature && subsidiesFeature.length > 0) {
    slides.unshift({
      backgroundColor: '#eef3fa',
      tagsGTM: {
        id: 'Subsidies',
        creative_name: 'Subsidies',
        creative_slot: 'subsidies_feature_1',
        promotion_id: 'p_subsidies',
        promotion_name: 'Reembolso',
      },
      objectFit: 'cover',
      src: `${baseUrl}/subsidies/subsidies-banner-sm.png`,
      link: `${CONST.FRONTEND_ENDPOINT}/meus-reembolsos`,
      md: {
        src: `${baseUrl}/subsidies/subsidies-banner-md.png`,
      },
      lg: {
        src: `${baseUrl}/subsidies/subsidies-banner-lg.png`,
      },
      title: `Incentivo à educação: Veja mais sobre os reembolsos.`,
    });
  }

  const fullIncluded = plan?.features?.some(
    (el) => el.name === 'full_included'
  );

  const customCategory = info?.colaborador.empresa.custom_category;

  const universityCard = ((): CourseType | null => {
    const label = user?.colaborador?.empresa?.corporate_university_name;
    const logo = user?.colaborador?.empresa?.logo;

    return label && logo
      ? {
          label,
          link: '/corporate_university/trilhas',
          image: `${CONST.API_ENDPOINT}/contents/categorias/${logo}`,
          description: '',
          type: 'university',
        }
      : null;
  })();

  const courseTypesWithLink = (() => {
    const cards = [];

    if (landingPageFeature && landingPageFeature.length > 0) {
      cards.unshift({
        description: 'Aproveite essa exclusividade da Unibrad.',
        image: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner Card-M.png`,
        label: 'Pós-graduação UniBrad',
        link: `${CONST.FRONTEND_ENDPOINT}/cursos/novo_catalogo?unibrad_included=true`,
        type: 'custom-category',
      });
    }

    if (universityCard) {
      cards.push(universityCard);
    }

    if (customCategory) {
      cards.push({
        description: 'Recomendados pela sua empresa',
        image: `${constants.API_ENDPOINT}/contents/categorias/${info.colaborador.empresa.logo}`,
        label: customCategory,
        link: '/cursos/custom-category',
        type: 'custom-category',
      });
    }

    if (fullIncluded) {
      cards.push({
        label: 'Inclusos no seu plano',
        link: 'cursos/novo_catalogo?feature_full=true',
        type: 'full',
      });
    }

    courseTypes.forEach((courseType) => {
      cards.push({
        ...courseType,
        link: `cursos/novo_catalogo?course_types=${courseType.type}`,
      });
    });

    return cards;
  })();

  const knowledgeAreasWithLink = knowledgeAreas.map((knowledgeArea) => ({
    ...knowledgeArea,
    link: `cursos/novo_catalogo?knowledge_areas=${knowledgeArea.id}`,
  }));

  return (
    <Container fixed maxWidth="xl">
      <ContentContainer>
        <GtmContext>
          <CursosSection>
            <Carousel infinite={slides.length > 1} slides={slides} />
          </CursosSection>

          <HomeRecommendations />
        </GtmContext>

        <CursosSection title="Encontre o curso ideal para você">
          {isBootstrapDataPending && <CourseTypeListLoading />}
          {!isBootstrapDataPending && courseTypesWithLink.length > 0 && (
            <CourseTypeList data={courseTypesWithLink} />
          )}
        </CursosSection>

        <CursosSection title="Explore cursos por Temas">
          {isBootstrapDataPending && <KnowledgeAreaListLoading />}
          {!isBootstrapDataPending && knowledgeAreasWithLink.length > 0 && (
            <KnowledgeAreaList data={knowledgeAreasWithLink} />
          )}
        </CursosSection>
      </ContentContainer>
    </Container>
  );
};

export default CursosHome;
