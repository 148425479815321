import * as React from 'react';

import { PageContainer, SectionHeader } from '@/react/components/idp/shared';

import {
  Alert,
  Box,
  Grid,
  Stack,
  ToggleButton,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { ToggleButtonGroup } from '@/react/components/toggle-buttons';
import { useCreatePlan } from '@/react/data/idp';
import { CreatePlanWizardSteps } from '../create-plan';
import { useCreateRecommendation } from '@/react/data/idp/useCreateRecommendation';

export const RecommendationTypes = {
  AI_RECOMMENDATION: 'aiRecommendation',
  NO_RECOMMENDATION: 'noRecommendation',
} as const;

export const validationSchema = z.object({
  recommendationType: z
    .string()
    .min(1, { message: 'Selecione uma opção para continuar.' }),
});

const handleBackToPlanList = () => {
  window.location.assign('/pdi/planos');
};

export const RecommendationTypeSelect = () => {
  const { createRecommendation } = useCreateRecommendation({
    onError: handleBackToPlanList, // arrumar
    onSuccess: (recommendation) => {
      // TODO: use the data in this object to send the user to the wizard url with the recommendation id
      window.location.assign('/pdi/planos/wizard');
    },
  });
  const defaultValues = {
    recommendationType: '', // TODO: This value should be loaded when we have the endpoint for recommendation (Check if it already exists before merging)
  };

  const { formState, handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  const { createPlan, isPending } = useCreatePlan();

  const handleChange = (_, value) => {
    if (value !== null) {
      setValue('recommendationType', value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const handleCreatePlan = async () => {
    await createPlan(
      { title: null },
      {
        onSuccess: (plan) => {
          window.location.assign(`/pdi/planos/${plan.id}`);
        },
      }
    );
  };

  const handleNext = async (data) => {
    if (data.recommendationType === RecommendationTypes.NO_RECOMMENDATION) {
      handleCreatePlan();
      return;
    }

    await createRecommendation(CreatePlanWizardSteps.DURATION);
  };

  const { recommendationType } = watch();

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        <SectionHeader
          handleBackNavigation={handleBackToPlanList}
          title="Voltar"
        />
        <Box mt={3}>
          <Typography variant="h6">
            Como você gostaria de criar seu plano?
          </Typography>
        </Box>
        <Box mt={3}>
          <form onSubmit={handleSubmit(handleNext)}>
            <ToggleButtonGroup
              aria-label="recommendation type"
              color="primary"
              exclusive
              fullWidth
              onChange={handleChange}
              size="large"
              value={recommendationType}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ToggleButton value={RecommendationTypes.AI_RECOMMENDATION}>
                    <Stack
                      sx={{
                        textAlign: 'left',
                      }}
                      spacing={0}
                      width="100%"
                    >
                      <Typography variant="body3" fontWeight="bold">
                        Recomendado por Inteligência Artificial
                      </Typography>
                      <Typography variant="body3">
                        Defina seus objetivos e deixe nossa IA criar um plano
                        personalizado para você. Após a recomendação, você pode
                        ajustar e personalizar o plano como desejar.
                      </Typography>
                    </Stack>
                  </ToggleButton>
                </Grid>
                <Grid item xs={12}>
                  <ToggleButton value={RecommendationTypes.NO_RECOMMENDATION}>
                    <Stack
                      sx={{
                        textAlign: 'left',
                      }}
                      spacing={0}
                      width="100%"
                    >
                      <Typography variant="body3" fontWeight="bold">
                        Sem recomendação
                      </Typography>
                      <Typography variant="body3">
                        Crie seu plano do zero, sem recomendações.
                      </Typography>
                    </Stack>
                  </ToggleButton>
                </Grid>
                {formState.isSubmitted &&
                  formState.errors['recommendationType'] && (
                    <Grid item xs={12}>
                      <Alert severity="error">
                        {
                          formState.errors['recommendationType']
                            .message as string
                        }
                      </Alert>
                    </Grid>
                  )}
              </Grid>
            </ToggleButtonGroup>
            <WizardStepActions>
              <LoadingButton
                color="primary"
                onClick={handleBackToPlanList}
                variant="text"
              >
                Voltar
              </LoadingButton>
              <LoadingButton
                color="primary"
                loading={isPending}
                onClick={() => {
                  trigger();
                }}
                type="submit"
                variant="contained"
              >
                Próximo
              </LoadingButton>
            </WizardStepActions>
          </form>
        </Box>
      </Box>
    </PageContainer>
  );
};
