import React from 'react';

import { State, StateActions } from './interfaces';

export const PlanProfileContext =
  React.createContext<[State, StateActions | null]>(null);

export function PlanProfileProvider({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: State;
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const defaultActions: StateActions = {
    edit: (context) => {
      dispatch({
        type: 'SET_EDIT_CONTEXT',
        payload: context,
      });
    },
    updateProfile: (values) => {
      dispatch({
        type: 'UPDATE_PROFILE',
        payload: values,
      });
    },
  };

  const actions = { ...defaultActions, ...initialState.actions };
  const value: [State, StateActions] = [state, actions];

  return (
    <PlanProfileContext.Provider value={value}>
      {children}
    </PlanProfileContext.Provider>
  );
}

function reducer(state: State, action): State {
  switch (action.type) {
    case 'SET_EDIT_CONTEXT': {
      return {
        ...state,
        editContext: action.payload,
      };
    }
    case 'UPDATE_PROFILE': {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    }
  }
}
