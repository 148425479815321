import * as React from 'react';
import { PlanProfileContext } from './plan-profile.context';

export function usePlanProfile() {
  const context = React.useContext(PlanProfileContext);

  if (!context) {
    throw new Error('usePlanProfile must be used within a ProfileContext');
  }

  const [state, actions] = context;
  const { editContext, profile } = state;

  return {
    actions,
    editContext,
    profile,
  };
}
