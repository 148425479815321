/**
 * @desc Converts a number into localized currency format
 * @param {number} amount - The amount to be formatted.
 * @param {string} currency - The currency to be used, defaults to 'BRL'.
 * @returns {string} The formatted currency.
 * @example
 * // currency is 'R$ 2.500,00'
 * formatMoney(2500);
 */
export const formatMoney = (
  amount: number | string,
  showFraction: boolean | undefined = true,
  currency: string | undefined = 'BRL'
): string => {
  const fractionDigitsSize = 2;

  const formatter = new Intl.NumberFormat('pt-BR', {
    currency,
    minimumFractionDigits: showFraction ? fractionDigitsSize : 0,
    maximumFractionDigits: showFraction ? fractionDigitsSize : 0,
    style: 'currency',
  });

  return formatter.format(+amount);
};
