import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getSvg } from '@/react/utils/assets';

const ResourceApprovalModal = ({
  open: externalOpen,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [open, setOpen] = React.useState(externalOpen);

  React.useEffect(() => {
    setOpen(externalOpen);
  }, [externalOpen]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle />
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          color: ({ palette }) => palette.grey[500],
          position: 'absolute',
          right: ({ spacing }) => spacing(2),
          top: ({ spacing }) => spacing(2.5),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={3}>
          <Box
            component="img"
            src={getSvg('no_available_balance.svg')}
            sx={{ width: 128 }}
          />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Não é possível pedir reembolso para este curso.
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography component="p" variant="body3">
            O curso que você selecionou não tem custo e, por isso, não é
            possível pedir reembolso para ele.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResourceApprovalModal;
