import * as React from 'react';
import { WizardProps } from './interfaces';

import Step from './step.component';
import { useWizard } from './state/useWizard';

const Wizard: React.ElementType<WizardProps> = ({ steps }) => {
  const { currentStepId } = useWizard();
  const currentStep = steps[currentStepId];

  return <Step {...currentStep} />;
};

export default Wizard;
