import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { EmailRegex } from 'powership';
import { FormControlLabel } from '@mui/material';

export const FLAG_ACCEPT_EMAIL_MKT = 'accept_communication';

export function QuestionTypeEmailContact({
  value,
  errors,
  setErrors,
  setValue,
}) {
  const hasError = !!errors.length;

  const [emailValue = '', checkboxValue = FLAG_ACCEPT_EMAIL_MKT] = value;

  return (
    <Stack gap={3}>
      <span>
        Nos diga seu e-mail preferido para receber informações e novidades sobre
        a Galena! <br />É para ele que enviaremos as comunicações e instruções
        sobre suas matrículas e cursos, além de promoções especiais.
      </span>

      <TextField
        placeholder="Digite o seu e-mail"
        error={hasError}
        value={emailValue}
        onChange={(ev) => {
          const txt = ev.target.value;
          const isValid = EmailRegex.test(txt);

          if (isValid && hasError) {
            setErrors([]);
          } else if (!isValid && !hasError) {
            setErrors(['Email inválido']);
          }

          setValue([txt, checkboxValue]);
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxValue === FLAG_ACCEPT_EMAIL_MKT}
            defaultChecked
            onChange={(_, checked) => {
              setValue([emailValue, checked ? FLAG_ACCEPT_EMAIL_MKT : '']);
            }}
          />
        }
        label="Aceito receber comunicações nesse e-mail."
      />
    </Stack>
  );
}
