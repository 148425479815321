export enum LANGUAGES {
  FRENCH = 'frances',
  ENGLISH = 'ingles',
  MANDARIN = 'mandarim',
  SPANISH = 'espanhol',
  ITALIAN = 'italiano',
  JAPANESE = 'japones',
  GERMAN = 'alemao',
  KOREAN = 'coreano',
  DUTCH = 'holandes',
  PORTUGUESE = 'portugues_para_estrangeiros',
}

export const LanguagesLabels = {
  [LANGUAGES.FRENCH]: 'Francês',
  [LANGUAGES.ENGLISH]: 'Inglês',
  [LANGUAGES.MANDARIN]: 'Mandarim',
  [LANGUAGES.SPANISH]: 'Espanhol',
  [LANGUAGES.ITALIAN]: 'Italiano',
  [LANGUAGES.JAPANESE]: 'Japonês',
  [LANGUAGES.GERMAN]: 'Alemão',
  [LANGUAGES.KOREAN]: 'Coreano',
  [LANGUAGES.DUTCH]: 'Holandês',
  [LANGUAGES.PORTUGUESE]: 'Português para estrangeiros',
} as const;

export enum SENIORITIES {
  ANAYLIST = 'analyst',
  APPRENTICE = 'apprentice',
  ASSISTANT = 'assistant',
  ATTENDANT = 'attendant',
  AUXILIARY = 'auxiliary',
  COORDINATOR = 'coordinator',
  DIRECTOR = 'director',
  INTERN = 'intern',
  MANAGER = 'manager',
  OPERATOR = 'operator',
  SPECIALIST = 'specialist',
  SUPERVISOR = 'supervisor',
  TECHNICIAN = 'technician',
  VICE_PRESIDENT = 'vice_president',
}

export enum SCHOLARITIES {
  DOCTORAL = 'doctoral',
  HIGH_SCHOOL_COMPLETE = 'high_school_complete',
  HIGH_SCHOOL_INCOMPLETE = 'high_school_incomplete',
  POSTGRADUATE = 'postgraduate',
  UNDERGRADUATE_COMPLETE = 'undergraduate_complete',
  UNDERGRADUATE_INCOMPLETE = 'undergraduate_incomplete',
}

export enum GOALS {
  CARRER_CHANGE = 'career_change',
  CARRER_DEVELOPMENT = 'career_development',
  PERSONAL_DEVELOPMENT = 'personal_development',
}

export const GoalsLabels = {
  [GOALS.CARRER_DEVELOPMENT]: 'Evoluir na carreira',
  [GOALS.CARRER_CHANGE]: 'Mudança de carreira',
  [GOALS.PERSONAL_DEVELOPMENT]: 'Desenvolvimento pessoal',
} as const;

export const ScholaritiesLabels = {
  [SCHOLARITIES.HIGH_SCHOOL_INCOMPLETE]: 'Ensino Médio incompleto',
  [SCHOLARITIES.HIGH_SCHOOL_COMPLETE]: 'Ensino Médio completo',
  [SCHOLARITIES.UNDERGRADUATE_INCOMPLETE]: 'Ensino Superior incompleto',
  [SCHOLARITIES.UNDERGRADUATE_COMPLETE]: 'Ensino Superior completo',
  [SCHOLARITIES.POSTGRADUATE]: 'Pós-Graduação/Mestrado/MBA',
  [SCHOLARITIES.DOCTORAL]: 'Doutorado/Pós-Doutorado',
} as const;

export const SenioritiesLabels = {
  [SENIORITIES.APPRENTICE]: 'Jovem aprendiz',
  [SENIORITIES.INTERN]: 'Estagiário',
  [SENIORITIES.ATTENDANT]: 'Atendente',
  [SENIORITIES.OPERATOR]: 'Operador',
  [SENIORITIES.TECHNICIAN]: 'Técnico',
  [SENIORITIES.AUXILIARY]: 'Auxiliar',
  [SENIORITIES.ASSISTANT]: 'Assistente',
  [SENIORITIES.ANAYLIST]: 'Analista',
  [SENIORITIES.SUPERVISOR]: 'Supervisor',
  [SENIORITIES.COORDINATOR]: 'Coordenador',
  [SENIORITIES.SPECIALIST]: 'Especialista',
  [SENIORITIES.MANAGER]: 'Gerente',
  [SENIORITIES.DIRECTOR]: 'Diretor',
  [SENIORITIES.VICE_PRESIDENT]: 'Vice presidente/Presidente',
} as const;

export const MAX_BUDGET_PER_MONTH = '1000.0';
