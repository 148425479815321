import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

type StatesOptions = string[];
type CitiesByState = { [K: string]: string[] };

const requiredFieldMessages = {
  UF: 'Selecione um estado',
  city: 'Selecione uma cidade',
};

export function QuestionTypeAddress({ required, setValue, value, setErrors }) {
  const [stateDropdownOpen, setStateDropdownOpen] = React.useState(false);
  const [cityDropdownOpen, setCityDropdownOpen] = React.useState(false);
  const [{ statesOptions, citiesByState }, setStatesData] = React.useState<{
    statesOptions: StatesOptions;
    citiesByState: CitiesByState;
  }>({ statesOptions: [], citiesByState: {} });
  const [errors, componentSetErrors] = React.useState<string[]>([]);
  const [touched, setTouched] = React.useState([false, false]);

  const loading =
    (stateDropdownOpen || cityDropdownOpen) && statesOptions.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      import('@/data/estados.json').then((states) => {
        if (active) {
          const statesOptions = states.default.map((state) => state.sigla);
          const citiesByState: CitiesByState = {};
          states.default.forEach(
            (el) => (citiesByState[el.sigla] = el.cidades)
          );
          setStatesData({ statesOptions, citiesByState });
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const [uf = '', city = ''] = value;
  const [ufError = '', cityError = ''] = errors;

  const _setErrors = React.useCallback((errors: string[]) => {
    componentSetErrors(errors);
    setErrors(errors);
  }, []);

  React.useEffect(() => {
    if (!touched[0] && !touched[1]) {
      return;
    }

    if (!required) {
      if (uf && !city) {
        _setErrors([null, requiredFieldMessages['city']]);
      } else {
        _setErrors([]);
      }
    }

    if (required) {
      const UFError = touched[0] ? !uf && requiredFieldMessages['UF'] : '';
      const cityError = touched[1]
        ? !city && requiredFieldMessages['city']
        : '';

      if (!UFError && !cityError) {
        _setErrors([]);
      } else {
        _setErrors([null, requiredFieldMessages['city']]);
      }
    }
  }, [uf, city]);

  return (
    <Stack width={'100%'} gap={2}>
      <Autocomplete
        clearOnEscape
        fullWidth
        isOptionEqualToValue={(a, b) => a === b}
        value={uf || null}
        onChange={(_, option) => {
          setValue([option, '']);
          setTouched([true, touched[1]]);
        }}
        onClose={() => {
          setStateDropdownOpen(false);
        }}
        onOpen={() => {
          setStateDropdownOpen(true);
        }}
        open={stateDropdownOpen}
        options={statesOptions}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={!!ufError}
              helperText={ufError}
              label="Estado"
            />
          );
        }}
      />

      {uf && (
        <Autocomplete
          clearOnEscape
          fullWidth
          isOptionEqualToValue={(a, b) => a === b}
          value={city || null}
          onChange={(_, option) => {
            setValue([uf, option]);
            setTouched([touched[0], true]);
          }}
          onClose={() => {
            setCityDropdownOpen(false);
          }}
          onOpen={() => {
            setCityDropdownOpen(true);
          }}
          open={cityDropdownOpen}
          options={citiesByState[uf] || []}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={!!cityError}
                helperText={cityError}
                label="Cidade"
              />
            );
          }}
        />
      )}
    </Stack>
  );
}
