import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Radio,
} from '@mui/material';
import { styledBoxes } from '@/react/components/quiz/utils/styled-boxes';
import { styled } from '@mui/material/styles';

const Styled = styledBoxes('RadioQuestion', {
  List: {
    gap: 2,
    component: styled(List)`
      width: 100%;
      padding-top: 0;
      li {
        padding-left: 0;
        padding-right: 0;
      }
    `,
    bgcolor: 'background.paper',
  },
  ListItemButton: {
    component: styled(ListItemButton)`
      border-radius: ${(p) => p.theme.spacing(1)};
      border-color: ${(p) => p.theme.palette.divider};
      border-width: 0.5px;
      border-style: solid;
    `,
  },
});

export function QuestionTypeRadio({ props, setValue, value }) {
  const { options } = props;
  return (
    <Styled.List>
      {options.map((option) => {
        const labelId = `checkbox-list-secondary-label-${option.value}`;
        const checked = value.includes(option.value);

        const onClick = () => {
          if (value !== option.value) {
            setValue([option.value]);
          }
        };

        return (
          <ListItem
            key={option.value}
            secondaryAction={
              <Radio
                edge="end"
                checked={checked}
                inputProps={{ 'aria-labelledby': labelId }}
                onClick={onClick}
              />
            }
          >
            <Styled.ListItemButton onClick={onClick}>
              {option.icon ? (
                <ListItemIcon>
                  <img src={option.icon} alt={'icon'} />
                </ListItemIcon>
              ) : null}

              <ListItemText id={labelId} primary={option.label} />
            </Styled.ListItemButton>
          </ListItem>
        );
      })}
    </Styled.List>
  );
}
