import { styled } from '@mui/material/styles';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, color, size }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      textTransform: 'none',

      ...(size === 'large' && {
        padding: theme.spacing(3),
      }),
    },
    [`& .${toggleButtonGroupClasses.selected}`]: {
      borderColor: theme.palette[color].main,
    },
  })
);

export default StyledToggleButtonGroup;
