import {
  useQuery,
  useMutation,
  UseMutateAsyncFunction,
} from '@tanstack/react-query';
import { useNotification } from '@/react/components/notification';
import { RecommendationClient } from './recommendation-client';
import { Profile } from './interfaces';

export const useRecommendationProfileKey = 'useRecommendationProfile';

export type getRecommendationProfileResult = {
  error: boolean;
  isFetching: boolean;
  profile: Partial<Profile> | null;
};

type getProfileOptions = {
  profile?: Partial<Profile>;
  signal?: AbortSignal;
  forceResetProfile?: boolean;
};

type patchRecommendationProfileResult = {
  isPending: boolean;
  patchProfile: UseMutateAsyncFunction<Partial<Profile>, unknown, any, unknown>;
};

export const getRecommendationProfile = (
  options: getProfileOptions = {}
): getRecommendationProfileResult => {
  const result = useQuery<Partial<Profile>>({
    queryKey: [[useRecommendationProfileKey, 'getProfile'], options],
    queryFn: () => {
      return RecommendationClient.getProfile(options);
    },
    refetchOnMount: false,
  });

  const profile = result.data || null;

  return {
    error: !!result.error,
    isFetching: result.isFetching,
    profile,
  };
};

export const patchRecommendationProfile =
  (): patchRecommendationProfileResult => {
    const { toast } = useNotification();
    const { mutateAsync: patchProfile, isPending } = useMutation({
      mutationFn: (profile: Profile) => {
        return RecommendationClient.patchProfile({
          ...profile,
          id: profile.id,
        });
      },
      onSuccess: () => {
        toast({
          content: 'Perfil atualizado',
          type: 'success',
        });
      },
      onError: () => {
        toast({
          content: 'Erro ao atualizar perfil',
          type: 'error',
        });
      },
    });

    return {
      patchProfile,
      isPending,
    };
  };
