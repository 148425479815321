import React, { useState } from 'react';
import { IdpConfirmDialog } from '@/react/components/idp/dialogs';

import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';

import LoadingButton from '@mui/lab/LoadingButton';
import { useWizard } from '@/react/components/wizard/state';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { z } from 'zod';
import { Box, Grid, Stack, Typography, ToggleButton } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useSetTitle } from '@/react/hooks/useSetTitle';
import { ToggleButtonGroup } from '@/react/components/toggle-buttons';

export const validationSchema = z.object({
  recommendedActivitiesSelected: z.string().array(),
});

const activities = [
  {
    title: 'Fazer uma faculdade de desenvolvimento de sistemas',
    description: 'Vai ser bom, confia!',
    id: 'activityA',
  },
  {
    title: 'Fazer uma faculdade de desenvolvimento de sistemas',
    description: 'Vai ser bom, confia!',
    id: 'activityB',
  },
];

export const PlanRecommendationReview: React.ElementType<
  WizardStepComponent
> = ({ onPrevious }) => {
  useSetTitle('Recomendação');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { actions: wizardActions, values } = useWizard();
  const defaultValues = {
    recommendationReview:
      values[CreatePlanWizardSteps.RECOMMENDATION_REVIEW] || [],
  };

  const { handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const handleNext = async (data) => {
    wizardActions.updateValues({
      [CreatePlanWizardSteps.RECOMMENDATION_REVIEW]: data.recommendationReview,
    });

    // TODO: implement subsequent steps
    window.alert('TODO: implement subsequent steps');
  };

  const { recommendationReview } = watch();

  const handleChange = (_, value) => {
    if (value !== null) {
      setValue('recommendationReview', value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      <Box mt={3}>
        <Typography variant="h6">
          Sugerimos o seguinte plano para você
        </Typography>
        <Typography variant="body3">
          Essas são as atividades recomendadas para o seu plano de
          desenvolvimento. Selecione quantas quiser utilizar. Algumas atividades
          vêm com recomendações de cursos. Você pode escolher entre uma das
          opções ou continuar sem nenhuma.
        </Typography>

        <Typography variant="body3">
          Na próxima etapa, você também pode adicionar atividades manualmente,
          se quiser complementar as recomendações selecionadas.
        </Typography>
      </Box>

      <Box sx={{ py: 3 }}>
        <form onSubmit={handleSubmit(handleNext)}>
          <ToggleButtonGroup
            aria-label="plans recommendation review"
            color="primary"
            fullWidth
            onChange={handleChange}
            size="large"
            value={recommendationReview}
          >
            <Grid container spacing={2}>
              {activities.map((activity) => (
                <Grid item xs={12} key={activity.id}>
                  <ToggleButton value={activity.id}>
                    <Stack
                      sx={{
                        textAlign: 'left',
                      }}
                      spacing={0}
                      width="100%"
                    >
                      <Typography variant="body3" fontWeight="bold">
                        {activity.title}
                      </Typography>
                      <Typography variant="body3">
                        {activity.description}
                      </Typography>
                    </Stack>
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
          </ToggleButtonGroup>
          <WizardStepActions>
            <LoadingButton
              color="primary"
              onClick={() => setIsConfirmModalOpen(true)}
              variant="text"
            >
              Refazer a recomendação
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                trigger();
              }}
              color="primary"
              type="submit"
              variant="contained"
            >
              Criar Plano
            </LoadingButton>
          </WizardStepActions>
        </form>
      </Box>
      <IdpConfirmDialog
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={onPrevious}
        actionLabels={{
          confirm: 'Refazer a recomendação',
          cancel: 'Voltar',
        }}
        title="Tem certeza de que deseja refazer a recomendação?"
        subtitle="As sugestões atuais serão descartadas, e você precisará responder ao questionário novamente."
        description="Lembre-se: é possível adicionar manualmente outras atividades após esta etapa de recomendação."
      />
    </>
  );
};
