import { CreatePlan } from '@/react/components/idp/create-plan';
import { Component } from '@angular/core';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
})
export class IdpCreatePlanComponent {
  IdpCreatePlanComponent = CreatePlan;

  constructor() {}
  ngOnInit() {}
}
