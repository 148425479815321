import * as React from 'react';
import { Box } from '@mui/material';

import { PageContainer, SectionHeader } from '@/react/components/idp/shared';
import { Wizard } from '@/react/components/wizard';
import { WizardProvider, useWizard } from '@/react/components/wizard/state';
import { WizardSteps } from '@/react/components/wizard/interfaces';
import { State as WizardState } from '@/react/components/wizard/state/interfaces';
import { PlanProfileProvider } from '@/react/components/idp/create-plan/state';

import { PlanDurationForm } from './plan-duration-form.component';
import { GenerateRecommendation } from './generate-recommendation.component';
import { PlanSkillSelectionForm } from './plan-skill-selection-form.component';
import { PlanProfile } from './plan-profile.component';
import { PlanRecommendationReview } from './plan-recommendation-review.component';
import { ConfirmDiscardRecommendationDialog } from '@/react/components/idp/dialogs/confirm-discard-recommendation-dialog.component';
import { useDeleteRecommendation } from '@/react/data/idp/useDeleteRecommendation';

export enum StepsIds {
  DURATION = 'duration',
  GENERATE_RECOMMENDATION = 'generateRecommendation',
  PROFILE = 'profile',
  RECOMMENDATION_REVIEW = 'recommendationReview',
  RECOMMENDATION_TYPE = 'recommendationType',
  SKILL_SELECTION = 'skillSelection',
}

const handleBackToPlanList = () => {
  window.location.assign('/pdi/planos');
};

const handleBackToRecommendationTypeSelect = () => {
  window.location.assign('/pdi/planos/novo');
};

const createWizardState = () => {
  const state: WizardState = {
    currentStepId: StepsIds.DURATION,
    values: {
      [StepsIds.DURATION]: null,
      [StepsIds.SKILL_SELECTION]: null,
      [StepsIds.GENERATE_RECOMMENDATION]: null,
      [StepsIds.RECOMMENDATION_REVIEW]: null,
    },
  };

  return state;
};

export const CreatePlan = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  // TODO: get recommendation id from URL
  const { deleteRecommendation } = useDeleteRecommendation('', () =>
    window.location.assign('/pdi/planos')
  );
  const { actions } = useWizard();

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleBackNavigation = () => {
    handleModalOpen();
  };

  const steps: WizardSteps = {
    [StepsIds.DURATION]: {
      component: PlanDurationForm,
      onNext: () => actions.navigate(StepsIds.PROFILE),
    },
    [StepsIds.PROFILE]: {
      component: (props) => (
        <PlanProfileProvider initialState={{ profile: null }}>
          <PlanProfile {...props} />
        </PlanProfileProvider>
      ),
      onNext: () => actions.navigate(StepsIds.SKILL_SELECTION),
      onPrevious: () => actions.navigate(StepsIds.DURATION),
    },
    [StepsIds.SKILL_SELECTION]: {
      component: PlanSkillSelectionForm,
      onNext: () => actions.navigate(StepsIds.GENERATE_RECOMMENDATION),
      onPrevious: () => actions.navigate(StepsIds.PROFILE),
    },
    [StepsIds.GENERATE_RECOMMENDATION]: {
      component: GenerateRecommendation,
      onPrevious: () => actions.navigate(StepsIds.SKILL_SELECTION),
    },
    [StepsIds.RECOMMENDATION_REVIEW]: {
      component: () => (
        <PlanRecommendationReview
          onPrevious={() => actions.navigate(StepsIds.DURATION)}
        />
      ),
    },
  };

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        <SectionHeader
          handleBackNavigation={handleBackNavigation}
          actions={[
            { label: 'Descartar plano', onClick: handleBackNavigation },
          ]}
          title="Voltar"
        />
        <Wizard steps={steps} />
      </Box>
      <ConfirmDiscardRecommendationDialog
        open={modalOpen}
        onCancel={handleModalClose}
        onContinueLater={handleBackToPlanList}
        onConfirm={deleteRecommendation}
      />
    </PageContainer>
  );
};

export const CreatePlanWithContext = () => {
  const initialState = createWizardState();

  return (
    <WizardProvider initialState={initialState}>
      <CreatePlan />
    </WizardProvider>
  );
};
