import * as React from 'react';
import Button from '@mui/material/Button';

import { BaseDialog } from '@/react/components/dialog/base-dialog.component';

export const defaultActionLabels = {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
};

export interface ConfirmDialogProps
  extends Omit<React.ComponentProps<typeof BaseDialog>, 'onClose'> {
  actionLabels?: {
    cancel?: string;
    confirm?: string;
  };
  children: React.ReactNode;
  disableConfirmButton?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string | React.ReactNode;
}

export const ConfirmDialog = ({
  actionLabels = {
    cancel: defaultActionLabels.cancel,
    confirm: defaultActionLabels.confirm,
  },
  children,
  disableConfirmButton = false,
  onClose,
  onConfirm,
  title,
  ...dialogProps
}: ConfirmDialogProps) => {
  const { disableEscapeKeyDown = true, open } = dialogProps;

  const Actions = () => {
    return (
      <>
        <Button onClick={onClose} variant="text">
          {actionLabels.cancel}
        </Button>
        <Button
          disabled={disableConfirmButton}
          onClick={onConfirm}
          variant="contained"
        >
          {actionLabels.confirm}
        </Button>
      </>
    );
  };

  return (
    <BaseDialog
      actionsComponent={<Actions />}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={onClose}
      open={open}
      title={title}
      {...dialogProps}
    >
      {children}
    </BaseDialog>
  );
};
