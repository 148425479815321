import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { CreatePlanType, Plan } from './interfaces';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';

const defaultErrorMessages = {
  createPlan: 'Erro ao criar plano.',
};

export function useCreatePlan(): useCreatePlanType {
  const { toast } = useNotification();
  const { mutate: createPlan, isPending } = useMutation({
    mutationFn: async (payload: CreatePlanType = {}) => {
      return IdpClient.createPlan(payload);
    },
    onError: (error: any) => {
      Sentry.captureException(error);

      const errorMessage =
        error.response?.data?.current_status[0] ||
        defaultErrorMessages.createPlan;

      toast({
        content: errorMessage,
        type: 'error',
      });
    },
  });

  return {
    createPlan,
    isPending,
  };
}

type useCreatePlanType = {
  createPlan: UseMutateFunction<Plan, unknown, any, unknown>;
  isPending: boolean;
};
