import { useSnackbar } from 'notistack';

import { useNotificationActions } from './interfaces';

export function useNotification() {
  const { enqueueSnackbar } = useSnackbar();
  const actions = {
    toast: ({ content, title, type, ...props }) =>
      enqueueSnackbar(content, {
        title,
        type,
        variant: 'toast',
        TransitionProps: {
          direction: 'down',
        },
        ...props,
      }),
  } as useNotificationActions;

  return { ...actions };
}
