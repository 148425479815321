import * as React from 'react';
import { Context } from './context';

export function useWizard() {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useWizard must be used within a WizardContext');
  }

  const [state, actions] = context;
  const { currentStepId, values } = state;

  return {
    actions,
    currentStepId,
    values,
  };
}
